<template>
  <!-- MODAL New Locking -->
  <div id="newLockingModal" class="col-12 justify-content-center" >
    <div class="col-12 m-0 p-0 section-container rounded-5 shadow">
      <!-- HEAD -->
      <div class="row overflow-hidden col-12 m-0 p-0 tx-clomos-dark rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="lock-open-outline" class="tx-18 me-2 align-bottom"></ion-icon>
            {{ dictionary["Nueva reserva"][session.user.language] }}
          </h6>
        </div>
        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleNewLockingModal')">
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

      <!-- CONTENT Prepare Locking -->
      <div class="bg-caja m-0 p-0 w-100 rounded-bottom-5 pe-3">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;" class="p-3">
          <div class="col-12 p-3 border rounded form-material"
            style="overflow: auto; overflow-y: hidden; display: flex; flex-flow: wrap;">

            <!-- CONTENT New Locking -->
            <form class="col-12 m-0 p-0">
              <div class="col-12 m-0 p-0">
                <label class="col-12 m-0 mb-2 p-0 tx-bold tx-clomos-dark text-nowrap">
                  {{ dictionary['Seleccione un Locker'][session.user.language] }}
                </label>
              </div>

              <div id="lockersMapContent" class="m-0 p-3"
                style="width: 100%; height: 200px; border-radius: 5px 5px 0 0;"> </div>
              <div v-if="lockersMap === undefined" class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>

              <div v-if="targetLocker.id != undefined" class="row col-12 m-0 mb-2 p-2 pt-0 bg-clomos rounded-bottom-5">

                <div class="form-group col-lg-3 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-white text-nowrap">
                    {{ dictionary['Locker'][session.user.language] + ":" }}
                  </label>
                  <span class="col-md-12 p-0 tx-clomos-white">
                    {{ targetLocker.label }}
                  </span>
                </div>

                <div class="form-group col-lg-3 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-white text-nowrap">
                    {{ dictionary['Emplazamiento'][session.user.language] + ":" }}
                  </label>
                  <span class="col-md-12 p-0 tx-clomos-white">
                    {{ (targetLocker.emplacement != undefined && targetLocker.emplacement != "") ?
                      targetLocker.emplacement : this.dictionary["Sin localizar"][this.session.user.language] }}

                  </span>
                </div>

                <div class="form-group col-lg-3 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-white text-nowrap">
                    {{ dictionary['Huecos libres'][session.user.language] + ":" }}
                  </label>
                  <span class="col-md-12 p-0 tx-clomos-white">
                    {{ (targetLocker.variables != undefined && targetLocker.variables.analog["huecosLibres"] !=
                      undefined) ? targetLocker.variables.analog["huecosLibres"].value : dictionary['Sin configurar'][session.user.language]}}
                  </span>
                </div>

                <div class="form-group col-lg-3 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-white text-nowrap">
                    {{ dictionary['Huecos ocupados'][session.user.language] + ":" }}
                  </label>
                  <span class="col-md-12 p-0 tx-clomos-white">
                    {{ (targetLocker.variables != undefined && targetLocker.variables.analog["huecosOcupados"] !=
                      undefined) ? targetLocker.variables.analog["huecosOcupados"].value : dictionary['Sin configurar'][session.user.language]}}
                  </span>
                </div>

              </div>

              <div v-else class="row col-12 m-0 mb-2 p-2 bg-clomos rounded-bottom-5" style="background-color: black;">
                <span id="displayNewLockingLockerId" class="col-md-12 p-0 tx-clomos-white">
                  {{ dictionary['Seleccione un Locker'][session.user.language] }}
                </span>
              </div>

              <!-- LOCKING DATA -->
              <div class="col-12 m-0 p-0">
                <label class="col-12 m-0 p-0 tx-bold tx-clomos-dark text-nowrap">
                  {{ dictionary['Datos de la reserva'][session.user.language] }}
                </label>
              </div>

              <div class="row col-12 m-0 p-0">

                <div class="form-group col-lg-6 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['ID pedido'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <input v-model="newLockingData.id" id="inputNewLockingId" type="text"
                      class="form-control form-control-line ps-0 pe-0" style="color:black;">
                  </div>
                </div>

                <div class="form-group col-lg-6 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Observaciones'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <textarea v-model="newLockingData.observations" id="inputNewLockingObservations" rows="1"
                      class="form-control form-control-line ps-0 pe-0" style="color:black;" />
                  </div>
                </div>

              </div>

              <div class="row col-12 m-0 p-0">

                <div class="form-group col-lg-4 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['E-mail'][session.user.language] + " (" + dictionary['Cliente'][session.user.language]
                      + ")" }}
                  </label>
                  <div class="col-md-12 p-0">
                    <input v-model="newLockingData.userEmail" id="inputNewLockingUserEmail" type="text"
                      class="form-control form-control-line ps-0 pe-0" style="color:black;">
                  </div>
                </div>
                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Idioma'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <select id="selectNewEmailLanguage" style="color:black;" v-model="newLockingData.userEmailLanguage"
                      class="form-control form-control-line ps-0 pe-0">
                      <option value="es">{{ dictionary['Español'][session.user.language] }}</option>
                      <option value="en">{{ dictionary['Inglés'][session.user.language] }}</option>
                      <option value="eu">{{ dictionary['Euskera'][session.user.language] }}</option>
                    </select>
                  </div>
                </div>
                <div class="form-group col-lg-4 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['E-mail'][session.user.language] + " (" +
                      dictionary['Transportista'][session.user.language] + ")" }}
                  </label>
                  <div class="col-md-12 p-0">
                    <input v-model="newLockingData.transportEmail" id="inputNewLockingTransportEmail" type="text"
                      class="form-control form-control-line ps-0 pe-0" style="color:black;">
                  </div>
                </div>

                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Idioma'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <select id="selectNewEmailLanguage" style="color:black;"
                      :placeholder="dictionary['Idioma'][session.user.language]"
                      v-model="newLockingData.transportEmailLanguage" class="form-control form-control-line ps-0 pe-0">
                      <option value="es">{{ dictionary['Español'][session.user.language] }}
                      </option>
                      <option value="en">{{ dictionary['Inglés'][session.user.language] }}
                      </option>
                      <option value="eu">{{ dictionary['Euskera'][session.user.language] }}
                      </option>
                    </select>
                  </div>
                </div>

              </div>

              <!-- DELIVERY DATA -->
              <div class="col-12 m-0 p-0">
                <label class="col-12 m-0 p-0 tx-bold tx-clomos-dark text-nowrap">
                  {{ dictionary['Datos de la entrega'][session.user.language] }}
                </label>
              </div>

              <div class="row col-12 m-0 p-0">

                <div class="form-group col-md-6 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Fecha prevista'][session.user.language] }}
                  </label>
                  <div class="col-12 p-0">
                    <input v-model="newLockingData.scheduledDateTime" id="inputNewLockingScheduledDateTime" type="date"
                      class="form-control form-control-line ps-0 pe-0" style="color:black;">
                  </div>
                </div>

                <div class="form-group col-md-3 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    <!-- {{ dictionary['Días de espera'][session.user.language]}} -->Max. Dias en locker
                  </label>
                  <div class="col-12 p-0">
                    <input v-model="newLockingData.maxHoldingDays" id="inputNewLockingMaxHoldingDays" type="number"
                      min=1 class="form-control form-control-line ps-0 pe-0" style="color:black;">
                  </div>
                </div>

                <div class="form-group col-md-3 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Reserva accesible'][session.user.language] }}
                  </label>
                  <div class="col-12 p-0">
                    <div class="inputEditSlot form-control form-control-line border-0 tx-bold c-pointer"
                      :class="newLockingData.accessible ? 'true' : 'false'"
                      @click="newLockingData.accessible ? newLockingData.accessible = false : newLockingData.accessible = true">
                      {{ newLockingData.accessible ? "Sí" : "No" }}
                    </div>
                  </div>
                </div>

              </div>

              <div class="col-12 m-0 p-0">
                <label class="col-12 m-0 p-0 tx-bold tx-clomos-dark text-nowrap">
                  {{ dictionary['Bultos'][session.user.language] }}
                </label>
              </div>

              <div class="row m-0 p-2 border-2 rounded justify-content-around">
                <!-- NEW BULGE -->
                <div class="form-group col-lg-5 col-md-6 col-12 p-1 border-3 m-2 mx-auto text-center"
                  style="gap: 10px;">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Ambiente'][session.user.language] }}
                  </label>
                  <div class="d-flex flex-column flex-md-row align-items-center">
                    <img class="img-fluid p-3 pt-1 rounded-5" src="@/assets/img/box-small.jpg"
                      style="width: 150px; height: auto;" @click="incrementCounter('smallBulge')" />
                    <div class="d-flex flex-column flex-md-row align-items-center align-items-md-start ms-md-3">
                      <span class="tx-26 tx-clomos-light pe-2">X{{ counters.smallBulge }}</span>
                      <span class="tx-11 align-self-center pe-2">625x190X475mm</span>
                      <ion-icon name="remove-circle-outline"
                        class="bg-danger tx-white c-pointer targets-icons m-0 mt-auto mb-auto p-0 border rounded-circle tx-24 text-bold "
                        style="vertical-align: middle;" @click="decrementCounter('smallBulge')"></ion-icon>
                    </div>
                  </div>
                  <div class="d-flex flex-column flex-md-row align-items-center mt-3">
                    <img class="img-fluid p-3 pt-1 rounded-5" src="@/assets/img/box.png"
                      @click="incrementCounter('bigBulge')" style="width: 150px; height: auto;" />
                    <div class="d-flex flex-column flex-md-row align-items-center align-items-md-start ms-md-3">
                      <span class="tx-26 tx-clomos-light pe-2">X{{ counters.bigBulge }}</span>
                      <span class="tx-11 align-self-center pe-2">625x390X475mm</span>
                      <ion-icon name="remove-circle-outline"
                        class="bg-danger tx-white c-pointer targets-icons m-0 mt-auto mb-auto p-0 border rounded-circle tx-24 text-bold "
                        style="vertical-align: middle;" @click="decrementCounter('bigBulge')"></ion-icon>
                    </div>
                  </div>
                </div>
                <div class="form-group col-lg-5 col-md-6 col-12 p-1 border-3 m-2 mx-auto text-center"
                  style="gap: 10px;">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Frío'][session.user.language] }}
                  </label>
                  <div class="d-flex flex-column flex-md-row align-items-center mt-5 pt-3">
                    <img class="img-fluid p-3 pt-1 rounded-5" src="@/assets/img/box.png"
                      style="width: 150px; height: auto;" @click="incrementCounter('coldBulge')" />
                    <div class="d-flex flex-column flex-md-row align-items-center align-items-md-start ms-md-3">
                      <span class="tx-26 tx-clomos-light pe-2">X{{ counters.coldBulge }}</span>
                      <span class="tx-11 align-self-center pe-2">500x390x380mm</span>
                      <ion-icon name="remove-circle-outline"
                        class="bg-danger tx-white c-pointer targets-icons m-0 mt-auto mb-auto p-0 border rounded-circle tx-24 text-bold "
                        style="vertical-align: middle;" @click="decrementCounter('coldBulge')"></ion-icon>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div v-if="newLockingData.bulgeList.length > 0" class="col-12 m-0 p-0 mt-2">
                <label class="col-12 m-0 p-0 tx-bold tx-clomos-dark text-nowrap">
                  <span>{{ dictionary['Número de bultos añadidos'][session.user.language] }} {{
                    newLockingData.bulgeList.length }}</span>
                </label>
              </div>
              <div class="row m-0 mt-2 p-0 border-2 rounded" v-for="target in newLockingData.bulgeList" :key="target">
                >
                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Ancho'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <div class="border-bottom tx-13 pb-1 text-nowrap" style="color:black;">
                      {{ target["ancho"] }}
                      <span class="ps-1 small">mm</span>
                    </div>
                  </div>
                </div>

                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Alto'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <div class="border-bottom tx-13 pb-1 text-nowrap" style="color:black;">
                      {{ target["alto"] }}
                      <span class="ps-1 small">mm</span>
                    </div>
                  </div>
                </div>

                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Fondo'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <div class="border-bottom tx-13 pb-1 text-nowrap" style="color:black;">
                      {{ target["fondo"] }}
                      <span class="ps-1 small">mm</span>
                    </div>
                  </div>
                </div> -->

              <!--                 <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Peso'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <div class="border-bottom tx-13 pb-1 text-nowrap" style="color:black;">
                    {{ target["peso"] }}
                    <span class="ps-1 small">g</span>
                    </div>
                  </div>
                </div> 

                <div class="form-group col-lg-2 col-12 p-1">
                  <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                    {{ dictionary['Tipo'][session.user.language] }}
                  </label>
                  <div class="col-md-12 p-0">
                    <div class="border-bottom tx-13 pb-1 text-nowrap" style="color:black;">
                      {{ dictionary[target["tipo"]][session.user.language] }}
                    </div>
                  </div>
                </div>

                <div class="form-group col-lg-2 col-12 m-0 mt-auto mb-auto p-0">
                  <ion-icon name="remove-circle-outline"
                    class="bg-danger tx-white c-pointer targets-icons m-0 mt-auto mb-auto p-0 border rounded-circle tx-36 text-bold "
                    style="vertical-align: middle;" @click="this.newLockingData.bulgeList.splice(target, 1)"></ion-icon>
                </div>

              </div>-->

              <div class="row">
                <!-- SUBMIT -->

                <div class="form-group col-lg-6 col-12 p-0 pt-3 m-0 ms-auto me-auto">
                  <div class="col-md-12">
                    <button type="button" id="btnSubmitCreateNewLocking" class="button-ok gradient-tablas rounded-3"
                      @click="setNewLockingData">
                      {{ dictionary['Crear nuevo pedido'][session.user.language] }}
                    </button>
                  </div>
                </div>

              </div>

            </form>

          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import $ from 'jquery';
import Leaflet from 'leaflet';
import { MarkerClusterGroup } from 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
// Clomos
import api from '@/services/api';

export default {
  name: "NewLockingModal",
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    productId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newLockingData: {
        userEmail: "",
        transportEmail: this.session.user.id,
        userEmailLanguage: this.session.user.language,
        transportEmailLanguage: this.session.user.language,
        id: "",
        lockerId: "",
        observations: "",
        scheduledDateTime: new Date(),
        maxHoldingDays: 1,
        accessible: false,
        bulgeList: [],
        releasedSlots: {},
      },
      emplacementList: [],
      lockerList: [],
      lockersMap: undefined,
      targetLocker: {},
      inputNewBulgeId: "",
      inputNewBulgeWidth: 0,
      inputNewBulgeHeight: 0,
      inputNewBulgeLength: 0,
      inputNewBulgeWeight: 1,
      inputNewBulgeType: "ambiente",
      newBulgeTypeList: ["frio", "ambiente"],
      showValidateButton: true,
      counters: {
        smallBulge: 0,
        bigBulge: 0,
        coldBulge: 0
      },
    }
  }, computed: {
    view() {
      return (
        this.inputNewBulgeWidth > 0 &&
        this.inputNewBulgeHeight > 0 &&
        this.inputNewBulgeLength > 0 &&
        this.inputNewBulgeType !== "" &&
        this.newLockingData.bulgeList.length === 0
      );
    }
  },
  watch: {
    'newLockingData.bulgeList.length'(newLength) {
      if (newLength === 0 && !this.showValidateButton) {
        this.showValidateButton = true;
      }
    }
  },
  emits: ["toggleNewLockingModal"],
  methods: {
    incrementCounter(type) {
      this.counters[type]++;
      this.updateBulgeList();
    },

    decrementCounter(type) {
      if (this.counters[type] > 0) {
        this.counters[type]--;
        this.updateBulgeList();
      }
    },
    updateBulgeList() {
      // Limpiar la lista de bultos
      this.newLockingData.bulgeList = [];

      // Agregar bultos pequeños
      for (let i = 0; i < this.counters.smallBulge; i++) {
        this.newLockingData.bulgeList.push({
          'ancho': 392,
          'alto': 199,
          'fondo': 510,
          'tipo': 'ambiente',
          'peso': 1,
          'accesible': this.newLockingData.accessible
        });
      }

      // Agregar bultos grandes
      for (let i = 0; i < this.counters.bigBulge; i++) {
        this.newLockingData.bulgeList.push({
          'ancho': 392,
          'alto': 399,
          'fondo': 510,
          'tipo': 'ambiente',
          'peso': 1,
         
        });
      }

      // Agregar bultos fríos
      for (let i = 0; i < this.counters.coldBulge; i++) {
        this.newLockingData.bulgeList.push({
          'ancho': 392,
          'alto': 399,
          'fondo': 510,
          'tipo': 'frio',
          'peso': 1,
         
        });
      }
    },


    // DISPLAY
    async displayLockersMap() {
      if (this.lockersMap != undefined) {
        this.lockersMap.invalidateSize();
        this.lockersMap.remove();
      }
      var lockersMap = Leaflet.map("lockersMapContent");
      Leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png?lang=' + this.session.user.language, { maxZoom: 19 }).addTo(lockersMap);


      var siteMarkersGroup = new MarkerClusterGroup();
      lockersMap.addLayer(siteMarkersGroup);

      let lockersMapListForGroup = [];
      siteMarkersGroup.addLayers(this.lockerList.map(l => {
        const options = { title: l.label, clickable: true, draggable: false };
        let coordinates = [0, 0];
        if (l.parent === "")
          coordinates = [l.geoJSON.geometry.coordinates[0], l.geoJSON.geometry.coordinates[1]]
        else for (let ii in this.emplacementList) if (l.parent === this.emplacementList[ii].id)
          coordinates = [this.emplacementList[ii].geoJSON.geometry.coordinates[0], this.emplacementList[ii].geoJSON.geometry.coordinates[1]]
        let emplacement = (l.emplacement != undefined && l.emplacement != "") ? l.emplacement : this.dictionary["Sin localizar"][this.session.user.language];
        var marker = Leaflet.marker(coordinates, options);
        marker.on("click", () => { this.targetLocker = l });
        let freeSlots = l.variables.analog["huecosLibres"].value;
        let totalSlots = l.variables.analog["huecosTotales"].value;
        marker.bindPopup('<div class="d-flex flex-column" style="min-width: 9rem;">'
          + '<span data-id="' + l.id + '" class="btnTargetLocker c-pointer tx-bold tx-grey m-0 p-0">' + l.label + '</span>'
          + '<div class="m-0 p-0 d-flex flex-column justify-content-between">'
          + '<text class="w-100">' + emplacement + '</text>'
          + '<text class="w-100"> Huecos libres:' + freeSlots + '</text>'
          + '<text class="w-100"> Huecos totales:' + totalSlots + '</text>'
          + '</div></div>');
        lockersMapListForGroup.push(marker);
        return marker;
      }))
      if (lockersMapListForGroup[0] != undefined) {
        var lockersMapGroup = Leaflet.featureGroup(lockersMapListForGroup);
        lockersMap.fitBounds(lockersMapGroup.getBounds());
      } else {
        lockersMap.setView(new Leaflet.LatLng(40.426, -3.7034), 10);
      }

      if ($(".leaflet-control-attribution").get()[0] != undefined) $(".leaflet-control-attribution").get()[0].remove();

      this.lockersMap = lockersMap;
    },
    // GET
    async getLockerList() {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: ""
      }
      let response = await api.getLockerListByCompany(request);
      this.lockerList = api.parseLockersFromResponse(response);
      await this.displayLockersMap();
    },
    async getEmplacementList(callback) {
      let request = {
        nifCif: "showAll",
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getLockerEmplacementList(request);
      this.emplacementList = api.parseEmplacementsFromResponse(response);
      if (callback && typeof callback === "function") callback(this.emplacementList);
    },
    // SET
    async setNewLockingData() {
      let valid = true;
      if (this.targetLocker.id != undefined) {
        this.newLockingData.lockerId = this.targetLocker.id;
      }
      else {
        $("#displayNewLockingLockerId").get()[0].style.color = "orange";
        valid = false;
      }
      if (this.newLockingData.id == "") {
        $("#inputNewLockingId").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newLockingData.userEmail == "" || !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.newLockingData.userEmail))) {
        $("#inputNewLockingUserEmail").get()[0].classList.add("missing")
        valid = false;
      }
       if (this.newLockingData.transportEmail == "" || !(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.newLockingData.transportEmail))) {
        $("#inputNewLockingTransportEmail").get()[0].classList.add("missing")
        valid = false;
      } 
      if (this.newLockingData.maxHoldingDays == "") {
        $("#inputNewLockingMaxHoldingDays").get()[0].classList.add("missing")
        valid = false;
      }
      
      if (!valid) {
        setTimeout(() => {        
          $("#inputNewLockingUserEmail").get()[0].classList.remove("missing")
          $("#inputNewLockingTransportEmail").get()[0].classList.remove("missing")
          $("#inputNewLockingScheduledDateTime").get()[0].classList.remove("missing")
          $("#inputNewLockingMaxHoldingDays").get()[0].classList.remove("missing")
        }, 3000);
        return;
      }
      for (let i in this.newLockingData.bulgeList) {
        this.newLockingData.bulgeList[i]["id"] = Number(i) + 1;
        this.newLockingData.bulgeList[i]["accesible"] = this.newLockingData.accessible
      }
      let response = await api.postNewLocking(this.newLockingData);
      if (response["status-code"] === "200") {
        this.$emit('toggleNewLockingModal');
        window.alert("La reserva ha sido creada satisfactoriamente.");
      } else {
        window.alert("Error al crear la reserva.");
      }

    },
    // TARGET
    onTargetLocker(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      for (let i in this.lockerList) {
        if (this.lockerList[i].id === event.target.getAttribute('data-id')) this.targetLocker = this.lockerList[i];
      }
    },  

    // BUTTONS
    setButtonFunctions() {
      setTimeout(() => {
        $(".btnTargetLocker").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetLocker);
        });
      }, 300);
    },
  },
  async created() {
    let today = new Date();
    this.newLockingData.scheduledDateTime = new Date(today.setDate(today.getDate() + 1));
    await this.getEmplacementList();
    await this.getLockerList();
  }
}
</script>

<style>
#newLockingModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  padding: 15vh 20%
}

.targets-icons:hover {
  color: var(--main-dark-50);
}



@media (max-width: 1024px) {
  #newLockingModal {
    width: 95vw; 
    height: 95vh; 
    top: 2.5vh; 
    left: 2.5vw; 
    padding: 10vh 5%; 
  }
}


@media (max-width: 768px) {
  #newLockingModal {
    width: 100vw; 
    height: 100% !important; 
    top: 0; 
    left: 0; 
    padding: 5vh 2% !important;  
    overflow-y: auto !important; 
  }
}




</style>