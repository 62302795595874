<template>
  <!-- SECTION Installation Charts -->
  <div id="ChartsSection" class="row col-12 m-0 p-0">
    <div class="row col-12 m-0 p-0 section-container justify-content-center">
      <div class="col-lg-12 col-md-12 col-12 m-0 p-0 overflow-hidden justify-content-center">
        <div class="row col-lg-12 col-md-12 col-12 table-responsive m-0 p-0 pb-3">
          <div class="row col-lg-12 col-md-12 col-12 m-0 p-0 rounded-end-5 justify-content-center">

            <!-- HEADER Installation Charts -->
            <div class="col-xl-2 col-lg-2 col-md-3 col-12 m-0 p-2">
              <div class="m-auto p-auto ps-0 pe-0 bg-lat tx-white rounded-5 border-1">
                <div class="col-12 overflow-hidden justify-content-center">
                  <!--<ion-icon v-if="session.user.bookmark==='charts'" name="bookmark" class="bookmark-button"></ion-icon>
            <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
                  <h6 class="tx-left tx-clomos-white text-center mt-2 mb-2 pt-1">
                    <ion-icon name="stats-chart" class="tx-18 me-2 align-bottom"></ion-icon>
                    {{ dictionary["Series"][session.user.language] }}
                  </h6>
                </div>

                <div class="p-3 pt-0 bg-white rounded-bottom-5 text-dark shadow">
                  <!-- Selector de Sistema -->
                  <div class="col-12 m-0 p-0">
                    <hr class="m-0 p-0">
                    <select :value="currentCompany.nifCif"
                      class="form-control mt-3 mb-3 h-100 text-nowrap text-dark border bg-white"
                      style="border: solid 2px white ; color: black;" @change="onCurrentCompanyChanged">
                      <option v-if="availableCompanies[0] != undefined && availableCompanies.length > 1"
                        value="showAll"> {{
                          dictionary['Mostrar todo'][session.user.language] }} </option>
                      <option v-for="company in availableCompanies" :key="company.nifCif" :v-if="company.nifCif != session.user.nifCif
                        && company.relation != undefined
                        && company.relation.referenceCompany === this.session.user.nifCif" :value="company.nifCif">
                        {{ (company.nifCif === session.user.nifCif) ? "✪ " + company.name : company.name }}
                      </option>
                    </select>
                  </div>
                  <div v-if="this.moduleAvailable === 2">
                    <hr class="m-0 p-0">
                    <label class="col-12 m-0 p-0 pb-1 tx-12">
                      {{ dictionary['ID del dispositivo'][session.user.language] }}:
                    </label>
                    <div>
                      <!-- Campo de entrada para búsqueda -->
                      <input type="text" v-model="searchDeviceQuery" placeholder="Buscar dispositivo"
                        class="form-control mt-0 mb-2 text-dark" />

                      <!-- Select con opciones filtradas -->
                      <select v-model="targetDeviceId" @change="getDeviceData" id="selectTargetDevice"
                        class="form-control mt-0 mb-3 h-100 text-nowrap text-dark border bg-white"
                        style="padding-bottom: 3px;">
                        <option v-for="device in filteredDevices" :key="device.id" :value="device.id"
                          :selected="device.id === targetDeviceId">
                          {{ device.label }}
                        </option>
                      </select>
                    </div>

                    <label class="col-12 m-0 p-0 pb-1 tx-12">
                      {{ dictionary['Variable'][session.user.language] }}:
                    </label>
                    <select v-model="targetVariableId" id="selectTargetDevice" data-live-search="true"
                      class="form-control mt-0 mb-3 h-100 text-nowrap text-dark border bg-white"
                      style="padding-bottom: 3px;">
                      <option v-for="variable in historyVariables" :key="variable['variableId']"
                        :value="variable['variableId']" :selected="variable['variableId'] === targetVariableId">
                        {{ variable['label'][0].toUpperCase() + variable['label'].substring(1, variable['label'].length)
                        }}
                      </option>
                    </select>
                  </div>
                  <div v-if="this.moduleAvailable === 1">
                    <hr class="m-0 p-0">
                    <label class="col-12 m-0 p-0 pb-1 tx-12">
                      {{ dictionary['Activo'][session.user.language] }}:
                    </label>

                    <div>
                      <!-- Campo de entrada para búsqueda -->
                      <input type="text" v-model="searchMachineQuery" placeholder="Buscar activo"
                        class="form-control mt-0 mb-2 text-dark" />

                      <!-- Select con opciones filtradas -->
                      <select v-model="targetDeviceId" @change="getMachineData" id="selectTargetDevice"
                        class="form-control mt-0 mb-3 h-100 text-nowrap text-dark border bg-white"
                        style="padding-bottom: 3px;">
                        <option v-for="machine in filteredMachines" :key="machine.id" :value="machine.id"
                          :selected="machine.id === targetDeviceId">
                          {{ machine.label }}
                        </option>
                      </select>
                    </div>

                    <label class="col-12 m-0 p-0 pb-1 tx-12">
                      {{ dictionary['Variable'][session.user.language] }}:
                    </label>
                    <select v-model="targetVariableId" id="selectTargetDevice" data-live-search="true"
                      :disabled="!selectVariablesEnabled"
                      class="form-control mt-3 mb-3 h-100 text-nowrap text-dark border bg-white"
                      style="padding-bottom: 3px;">
                      <option v-for="variable in historyVariables" :key="variable['variableId']"
                        :value="variable['variableId']" :selected="variable['variableId'] === targetVariableId">
                        {{ variable['label'][0].toUpperCase() + variable['label'].substring(1, variable['label'].length)
                        }}
                      </option>
                    </select>
                  </div>
                  <!--  <div class="col-12 m-0 p-0" style="text-align: left;">
                    <label class="col-12 m-0 p-0 pb-1 tx-12">
                      {{ dictionary['Fecha inicio'][session.user.language] }}:
                    </label>
                    <input v-model="startDateTimeInput" id="inputStartDate"
                      :type="(session.browser === 'firefox') ? 'date' : 'datetime-local'"
                      class="dateInput tx-14 col-12 m-0 p-1">
                  </div>

                  <div class="col-12 m-0 p-0" style="text-align: left;">
                    <label class="col-12 m-0 p-0 pb-1 tx-12">
                      {{ dictionary['Fecha fin'][session.user.language] }}:
                    </label>
                    <input v-model="endDateTimeInput" id="inputEndDate"
                      :type="(session.browser === 'firefox') ? 'date' : 'datetime-local'"
                      class="dateInput tx-14 col-12 m-0 p-1">
                  </div> -->

                  <div
                    class="col-12 m-0 mt-3 mb-1 p-0 tx-bold tx-clomos-midtone pe-2 ps-2 d-flex justify-content-between"
                    style="text-align: left; vertical-align: center;">
                    <label class="m-0 p-0 tx-12">
                      {{ dictionary['Eje izquierdo'][session.user.language] }}:
                    </label>
                    <input v-model="checkboxTargetAxisLeft" @change="checkboxTargetAxisRight = !checkboxTargetAxisLeft"
                      id="checkboxTargetAxisLeft" type="checkbox" style="width: 1.25rem" class="m-0 p-0 h-100 d-inline">
                  </div>

                  <div
                    class="col-12 m-0 mt-1 mb-1 p-0 tx-bold tx-clomos-midtone pe-2 ps-2 d-flex justify-content-between"
                    style="text-align: left; vertical-align: center;">
                    <label class="m-0 p-0 tx-12">
                      {{ dictionary['Eje derecho'][session.user.language] }}:
                    </label>
                    <input v-model="checkboxTargetAxisRight" @change="checkboxTargetAxisLeft = !checkboxTargetAxisRight"
                      id="checkboxTargetAxisRight" type="checkbox" style="width: 1.25rem"
                      class="m-0 p-0 h-100 d-inline">
                  </div>

                  <div
                    class="col-12 m-0 mt-1 mb-1 p-0 tx-bold tx-clomos-midtone pe-2 ps-2 d-flex justify-content-between"
                    style="text-align: left; vertical-align: center;">
                    <label class="m-0 p-0 tx-12">
                      {{ dictionary['Escalonado'][session.user.language] }}:
                    </label>
                    <input v-model="checkboxStep" @click="checkboxStep = !checkboxStep" id="checkboxStep"
                      type="checkbox" style="width: 1.25rem" class="m-0 p-0 h-100 d-inline">
                  </div>
                  <div
                    class="col-12 m-0 mt-3 mb-1 p-0 tx-bold tx-clomos-midtone pe-2 ps-2 d-flex justify-content-between"
                    style="text-align: left; vertical-align: center;">
                    <label class="m-0 p-0 tx-12">
                      <!--  {{ dictionary['Barras'][session.user.language] }} -->Lineas:
                    </label>
                    <input v-model="lineChart" @change="barChart = !lineChart" id="checkboxTargetAxisLeft"
                      type="checkbox" style="width: 1.25rem" class="m-0 p-0 h-100 d-inline">
                  </div>

                  <div
                    class="col-12 m-0 mt-1 mb-1 p-0 tx-bold tx-clomos-midtone pe-2 ps-2 d-flex justify-content-between"
                    style="text-align: left; vertical-align: center;">
                    <label class="m-0 p-0 tx-12">
                      <!-- {{ dictionary['Lineas'][session.user.language] }} -->Barras:
                    </label>
                    <input v-model="barChart" @change="lineChart = !barChart" id="checkboxTargetAxisRight"
                      type="checkbox" style="width: 1.25rem" class="m-0 p-0 h-100 d-inline">
                  </div>
                  <hr class="m-0 p-0">

                  <div class="col-12 m-0 p-0">
                    <button type="button"
                      @click="this.moduleAvailable === 2 ? getDeviceVariableHistory(1) : getMachineVariableHistory(1)"
                      class="col-12 button-ok bg-lat mt-3 rounded-5 w-100 tx-12" id="btnGuardarPerfil">
                      {{ dictionary['Añadir en gráfica'][session.user.language] }} 1
                    </button>
                  </div>

                  <div class="col-12 m-0 p-0">
                    <button type="button"
                      @click="this.moduleAvailable === 2 ? getDeviceVariableHistory(2) : getMachineVariableHistory(2)"
                      class="col-12 button-ok bg-lat mt-3 rounded-5 w-100 tx-12" id="btnGuardarPerfil">
                      {{ dictionary['Añadir en gráfica'][session.user.language] }} 2
                    </button>
                  </div>

                </div>
              </div>
            </div>


            <div class="row col-xl-10 col-lg-10 col-md-9 col-12 m-0 p-0 rounded-end-5 justify-content-center">
              <!-- CARD Chart 1 -->
              <div id="cardChart1" class="p-2"
                :class="(chartSize['1'].max) ? 'col-xl-6 col-lg-12 col-md-12 col-12' : 'col-12'">
                <div class="p-0 rounded-5 shadow">
                  <div class="row col-12 bg-head rounded-top-5 border-1 p-0 m-0 text-white">
                    <div
                      class="col-xl-1 col-lg-1 col-md-2 col-12 d-flex justify-content-start align-items-center p-1 ps-3">
                      <span class="border-2 rounded-circle ps-2 pe-2">1</span>

                    </div>
                    <div
                      class="col-xl-9 col-lg-9 col-md-9 col-12 m-0 mb-1 mt-1 pt-0 ps-0 text-white justify-content-end d-flex flex-nowrap">
                      <!--<ion-icon name="calendar-outline"></ion-icon>-->
                      <label for="incidenceSelect"
                        class="form-label text-start tx-14 pt-2 me-2 mb-2 text-nowrap"><!--{{ dictionary['Fecha inicio'][session.user.language] }}-->
                        Inicio/Fin
                      </label>
                      <input v-model="startDateTimeInput[1]" id="inputStartDate"
                        class="dateInput form-control tx-10 rounded text-nowrap me-2"
                        :type="(session.browser === 'firefox') ? 'date' : 'datetime-local'"
                        style="border: solid 2px white ; max-width: 120px; max-height:34px;">
                      <!--<label for="incidenceSelect" class="form-label text-start tx-14 pt-2 me-2 mb-2">{{ dictionary['Fecha fin'][session.user.language] }}</label>-->
                      <input v-model="endDateTimeInput[1]" id="inputEndDate"
                        class="dateInput form-control tx-10 rounded text-nowrap me-1"
                        :type="(session.browser === 'firefox') ? 'date' : 'datetime-local'"
                        style="border: solid 2px white ; max-width: 120px; max-height:34px;">

                      <!--<ion-icon name="search-circle-outline" class="text-dark p-0 m-0 pt-1 me-2 tx-18 hydrated md"></ion-icon>-->
                      <!-- <span class="text-dark m-0 p-0 text-nowrap">{{ dictionary["Aplicar"][session.user.language]
                            }}</span> -->
                      <span class="d-flex justify-content-center align-items-center">
                        <ion-icon @click="applyChartUpdate(1)" class="c-pointer text-white tx-24 rounded"
                          name="refresh-outline"></ion-icon>
                      </span>

                    </div>

                    <div
                      class="col-xl-2 col-lg-2 col-md-7 col-12 d-flex justify-content-end align-items-center p-1 pe-2">
                      <ion-icon name="download-outline" class="tx-18 p-1 btn btnSizeChart" @click="generateCSV(1)">
                      </ion-icon>
                      <ion-icon name="trash-outline" class="tx-18 p-1 btn btnSizeChart"
                        @click=" chartHistory['1'] = {}">
                      </ion-icon>
                      <ion-icon v-if="chartSize['1'].max" name="expand-outline" class="tx-18 p-1 btn btnSizeChart"
                        @click="chartSize['1'].max = !chartSize['1'].max">
                      </ion-icon>
                      <ion-icon v-if="!chartSize['1'].max" name="contract-outline" class="tx-18 p-1 btn btnSizeChart"
                        @click="chartSize['1'].max = !chartSize['1'].max">
                      </ion-icon>
                    </div>
                  </div>

                  <div class="bg-white rounded-bottom-5 pt-3 pb-2 m-0">
                    <VueEcharts :option="chart1Options" id="Chart1Canvas" class="col-12 w-100 ps-0 pe-0"
                      style="height: 35.0rem;">
                    </VueEcharts>
                  </div>
                </div>
              </div>

              <!-- CARD Chart 2 -->
              <div id="cardChart2" class="p-2"
                :class="(chartSize['2'].max) ? 'col-xl-6 col-lg-12 col-md-6 col-12' : 'col-12'">
                <div class="p-0 rounded-5 shadow">
                  <div class="row col-12 bg-head rounded-top-5 border-1 p-0 m-0 text-white">
                    <div
                      class="col-xl-1 col-lg-1 col-md-2 col-12 d-flex justify-content-start align-items-center p-1 ps-3">
                      <span class="border-2 rounded-circle ps-2 pe-2">2</span>

                    </div>
                    <div
                      class="col-xl-9 col-lg-9 col-md-9 col-12 m-0 mb-1 mt-1 pt-0 ps-0 text-white justify-content-end d-flex flex-nowrap">
                      <!--<ion-icon name="calendar-outline"></ion-icon>-->
                      <label for="incidenceSelect"
                        class="form-label text-start tx-14 pt-2 me-2 mb-2 text-nowrap"><!--{{ dictionary['Fecha inicio'][session.user.language] }}-->
                        Inicio/Fin
                      </label>
                      <input v-model="startDateTimeInput[2]" id="inputStartDate"
                        class="dateInput form-control tx-10 rounded text-nowrap me-2"
                        :type="(session.browser === 'firefox') ? 'date' : 'datetime-local'"
                        style="border: solid 2px white ; max-width: 120px; max-height:34px;">
                      <!--<label for="incidenceSelect" class="form-label text-start tx-14 pt-2 me-2 mb-2">{{ dictionary['Fecha fin'][session.user.language] }}</label>-->
                      <input v-model="endDateTimeInput[2]" id="inputEndDate"
                        class="dateInput form-control tx-10 rounded text-nowrap me-1"
                        :type="(session.browser === 'firefox') ? 'date' : 'datetime-local'"
                        style="border: solid 2px white ; max-width: 120px; max-height:34px;">

                      <!--<ion-icon name="search-circle-outline" class="text-dark p-0 m-0 pt-1 me-2 tx-18 hydrated md"></ion-icon>-->
                      <!-- <span class="text-dark m-0 p-0 text-nowrap">{{ dictionary["Aplicar"][session.user.language]
                            }}</span> -->
                      <span class="d-flex justify-content-center align-items-center">
                        <ion-icon @click="applyChartUpdate(2)" class="text-white  tx-24 rounded c-pointer"
                          name="refresh-outline"></ion-icon>
                      </span>
                    </div>

                    <div
                      class="col-xl-2 col-lg-2 col-md-7 col-12 d-flex justify-content-end align-items-center p-1 pe-2">
                      <ion-icon name="download-outline" class="tx-18 p-1 btn btnSizeChart" @click="generateCSV(2)">
                      </ion-icon>
                      <ion-icon name="trash-outline" class="tx-18 p-1 btn btnSizeChart"
                        @click=" chartHistory['2'] = {}">
                      </ion-icon>
                      <ion-icon v-if="chartSize['2'].max" name="expand-outline" class="tx-18 p-1 btn btnSizeChart"
                        @click="chartSize['2'].max = !chartSize['2'].max">
                      </ion-icon>
                      <ion-icon v-if="!chartSize['2'].max" name="contract-outline" class="tx-18 p-1 btn btnSizeChart"
                        @click="chartSize['2'].max = !chartSize['2'].max">
                      </ion-icon>
                    </div>
                  </div>

                  <div class="bg-white rounded-bottom-5 pt-3 pb-2 m-0">
                    <VueEcharts :option="chart2Options" id="Chart2Canvas" class="col-12 w-100 ps-0 pe-0"
                      style="height: 35.0rem;">
                    </VueEcharts>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
import { VueEcharts } from 'vue3-echarts';
import echartsOptionsJson from '@/assets/json/echarts_options.json';
// Clomos
import api from '@/services/api';


export default {
  name: "ChartsComponent",
  components: { VueEcharts },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    }
  },
  watch: {
    currentCompany: async function () {
      this.machineList = [];
      this.machineData = {};
      this.deviceData = {};
      this.moduleAvailable = this.getModulesFromUrl()
      if (this.moduleAvailable === 2) {
        await this.getDeviceListByCompany();
        if (this.availableDevices[0] != undefined) {
          this.targetDeviceId = this.availableDevices[0].id;
          await this.getDeviceData();
        }
        else {
          this.targetDeviceId = ""
        }
      } else if (this.moduleAvailable === 1) {

        await this.getMachineListByCompany()
        if (this.machineList[0] != undefined) {
          this.targetDeviceId = this.machineList[0].id;
          await this.getMachineData();
        }
        else {
          this.targetDeviceId = ""
        }
      }

      if (Object.entries(this.historyVariables)[0] != undefined) {
        this.targetVariableId = Object.entries(this.historyVariables)[0][0];
      }
      else {
        this.targetVariableId = ""
      }
    },
    '$route.params.currentModule': {
      handler: async function () {
        this.machineData = {};
        this.deviceData = {};
        await this.analyzeUrl();
      },
      immediate: true
    },
    async filteredMachines(newFilteredMachines) {

      if (newFilteredMachines.length > 0) {
        this.targetDeviceId = newFilteredMachines[0].id;
        await this.getMachineData()
      } else {
        this.targetDeviceId = null;
      }
    },
    async filteredDevices(newFilteredDevices) {

      if (newFilteredDevices.length > 0) {
        this.targetDeviceId = newFilteredDevices[0].id;
        await this.getDeviceData()
      } else {
        this.targetDeviceId = null;
      }
    },


  },
  data() {
    return {
      // LAST DAY CHART
      lineChartOptions: JSON.parse(JSON.stringify(echartsOptionsJson))["lineChartOptions"],
      deviceData: {
        id: "",
        nifCif: "",
        dataConsumed: "",
        label: "",
        dclId: "",
        dclTypeId: "",
        dclClass: "",
        type: "",
        distributionGroup: "",
        parent: "",
        isBase: "",
        isPublic: 0,
        token: "",
        imgSrc: "",
        emplacement: "",
        fechaUltimaComunicacion: "",
        tieneAlarmaActivada: "",
        version: "",
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [0, 0]
          }
        },
        variables: {
          analog: {},
          digital: {},
          dynamic: {}
        }
      },
      machineData: {
        id: "",
        nifCif: "",
        dataConsumed: "",
        label: "",
        dclId: "",
        dclTypeId: "",
        dclClass: "",
        type: "",
        distributionGroup: "",
        parent: "",
        isBase: "",
        isPublic: 0,
        token: "",
        imgSrc: "",
        emplacement: "",
        fechaUltimaComunicacion: "",
        tieneAlarmaActivada: "",
        version: "",
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [0, 0]
          }
        },
        variables: {
          analog: {},
          digital: {},
          dynamic: {}
        }
      },
      availableDevices: [],
      machineList: [],
      searchDeviceQuery: "",
      searchMachineQuery: "",
      targetDeviceId: "",
      targetVariableId: "",
      checkboxTargetAxisLeft: true,
      checkboxTargetAxisRight: false,
      checkboxStep: false,
      startDateTimeInput: {
        "1": api.parseDateTimeToString(new Date().setDate(new Date().getDate() - 1)),
        "2": api.parseDateTimeToString(new Date().setDate(new Date().getDate() - 1)),
      },
      endDateTimeInput: {
        "1": api.parseDateTimeToString(new Date()),
        "2": api.parseDateTimeToString(new Date()),
      },
      startDateTime: {
        "1": "",
        "2": "",
      },
      endDateTime: {
        "1": "",
        "2": "",
      },
      startDateTimeChart: {
        "1": "",
        "2": "",
      },
      endDateTimeChart: {
        "1": "",
        "2": "",
      },
      chartHistory: {
        "1": {},
        "2": {},
      },
      chartSize: {
        "1": { max: true },
        "2": { max: true }
      },
      // COLORS
      colors: ["#9e9e9e",
        "#4e79a7",
        "#f28d2b",
        "#e05759",
        "#76b7b2",
        "#59a14f",
        "#af7aa1",
        "#b1de89",
        "#595055",
        "#edc948",
        "#1c8356",
        "#ff9da7",
        "#8d9fca",
        "#2f451e"],
      selectVariablesEnabled: true,
      lineChart: true,
      barChart: false,
    }
  },
  computed: {
    filteredDevices() {
      if (!Array.isArray(this.availableDevices) || this.availableDevices.length === 0) {
        return [];
      }

      // Filtrar los dispositivos
      const filtered = this.availableDevices.filter(device => {
        const label = device.label;
        return typeof label === 'string' && label
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            this.searchDeviceQuery
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          );
      });


      filtered.sort((a, b) => {
        const labelA = a.label
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        const labelB = b.label
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        if (labelA < labelB) return -1;
        if (labelA > labelB) return 1;
        return 0; // son iguales
      });

      return filtered;
    },
    filteredMachines() {
      if (!Array.isArray(this.machineList) || this.machineList.length === 0) {
        return [];
      }

      // Filtrar las máquinas
      const filtered = this.machineList.filter(machine => {
        const label = machine.label;
        return typeof label === 'string' && label
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            this.searchMachineQuery
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          );
      });
      filtered.sort((a, b) => {
        const labelA = a.label
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        const labelB = b.label
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        if (labelA < labelB) return -1;
        if (labelA > labelB) return 1;
        return 0; // son iguales
      });

      return filtered;
    },
    fractionalLength() {
      return (localStorage.getItem("ClomosFractionalLength") != undefined) ? localStorage.getItem("ClomosFractionalLength") : 2;
    },
    historyVariables() {
      let historyVariables = {};
      if (this.moduleAvailable === 2) {
        for (let variableType in this.deviceData.variables)
          for (let variable in this.deviceData.variables[variableType])
            if (this.deviceData.variables[variableType][variable].saveHistory === 'true' && (variableType === 'analog' || variableType === 'digital')) {
              historyVariables[this.deviceData.variables[variableType][variable].variableId] = this.deviceData.variables[variableType][variable];
            }

      } else if (this.moduleAvailable === 1) {
        for (let variableType in this.machineData.variables)
          for (let variable in this.machineData.variables[variableType])
            if (this.machineData.variables[variableType][variable].saveHistory === 'true' && (variableType === 'analog' || variableType === 'digital')) {
              historyVariables[this.machineData.variables[variableType][variable].variableId] = this.machineData.variables[variableType][variable];
            }
      }

      return historyVariables;
    },
    chart1Options() {
      let chart1Options;
      chart1Options = JSON.parse(JSON.stringify(this.lineChartOptions));
      if (this.targetDeviceId == "") return chart1Options;
      chart1Options.axisPointer = {
        label: {
          formatter: ((dataTarget) => {
            let d = new Date(dataTarget.value);
            switch (this.session.user.dateTimeFormat) {
              case "DD/MM/YYYY":
                return String(d.getDate()).padStart(2, "0") + "/" + String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
              case "MM/DD/YYYY":
                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
              default:
                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
            }
          })
        }
      }
      chart1Options.xAxis = {
        min: new Date(this.startDateTimeInput["1"]).getTime(),
        max: new Date(this.endDateTimeInput["1"]).getTime(),
        axisLabel: {
          formatter: ((value) => {
            let d = new Date(value);
            switch (this.session.user.dateTimeFormat) {
              case "DD/MM/YYYY":
                return String(d.getDate()).padStart(2, "0") + "/" + String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
              case "MM/DD/YYYY":
                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
              default:
                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
            }
          }),
          fontSize: 9,

        }
      }
      chart1Options.tooltip = {
        trigger: "axis",
        axisPointer: {
          snap: true,
          lineStyle: {
            color: "#242e47",
            width: 2
          }
        },
        formatter: (params) => {
          let result = params[0].axisValueLabel + "<br/>";
          params.forEach(item => {
            let formattedValue = new Intl.NumberFormat(this.session.user.language === "en" ? 'en-IN' : 'es-ES', {
              maximumFractionDigits: this.fractionalLength
            }).format(Number(item.data[1]));

            result += `${item.marker} <strong>${item.seriesName}:</strong> ${formattedValue} ${(item.data[5] ?? '')} <br/>`;
          });

          return result;
        }
      };
      let i = 0;
      for (let variable in this.chartHistory["1"]) {
        chart1Options.yAxis[i] = {
          type: "value",
          axisLine: {
            lineStyle: {
              color: this.colors[i]
            }
          },
          name: variable.substring(0, 3).toUpperCase(),
          nameTextStyle: {
            fontSize: 10
          },
          axisLabel: {
            fontSize: 9,
            formatter: (value) => {
              return new Intl.NumberFormat(
                this.session.user.language === "en" ? "en-IN" : "es-ES",
                { maximumFractionDigits: Number(this.fractionalLength) }
              ).format(value) +
                ((this.chartHistory["1"][variable][i] !== undefined)
                  ? ` ${this.chartHistory["1"][variable][i][5]}`
                  : '');
            }
          },
          offset: Math.floor(i / 2) * 25,
          position: (this.chartHistory["1"][variable][i] != undefined)
            ? this.chartHistory["1"][variable][i][2]
            : (i % 2 === 0)
              ? "left"
              : "right"
        }
        chart1Options.legend.data[i] = variable;
        chart1Options.series[i] = {
          data: this.chartHistory["1"][variable],
          name: variable,
          yAxisIndex: i,
          type: (
            this.chartHistory["1"][variable] &&
            this.chartHistory["1"][variable][i] &&
            this.chartHistory["1"][variable][i][4] !== undefined
          ) ? this.chartHistory["1"][variable][i][4] : "line",
          symbol: "emptyDiamond",
          symbolSize: 6,
          color: this.colors[i],
          smooth: false,
          step: (this.chartHistory["1"][variable][i] != undefined &&this.chartHistory["1"][variable][i][3]==true ) ? 'end' : false,
          areaStyle: {
            color: this.colors[i],
            opacity: 0.03
          },
        }
        i += 1;
      }
      return chart1Options;
    },
    chart2Options() {
      let chart2Options;
      chart2Options = JSON.parse(JSON.stringify(this.lineChartOptions));
      if (this.targetDeviceId == "") return chart2Options;
      chart2Options.axisPointer = {
        label: {
          formatter: ((dataTarget) => {
            let d = new Date(dataTarget.value);
            switch (this.session.user.dateTimeFormat) {
              case "DD/MM/YYYY":
                return String(d.getDate()).padStart(2, "0") + "/" + String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
              case "MM/DD/YYYY":
                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
              default:
                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
            }
          })
        },
      }
      chart2Options.xAxis = {
        min: new Date(this.startDateTimeInput["2"]).getTime(),
        max: new Date(this.endDateTimeInput["2"]).getTime(),
        axisLabel: {
          formatter: ((value) => {
            let d = new Date(value);
            switch (this.session.user.dateTimeFormat) {
              case "DD/MM/YYYY":
                return String(d.getDate()).padStart(2, "0") + "/" + String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
              case "MM/DD/YYYY":
                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
              default:
                return String((d.getMonth() + 1)).padStart(2, "0") + "/" + String(d.getDate()).padStart(2, "0") + "/" + String(d.getFullYear()) + " \n " + String(d.getHours()).padStart(2, "0") + ":" + String(d.getMinutes()).padStart(2, "0");
            }
          }),
          fontSize: 9,
        },
      }
      chart2Options.tooltip = {
        trigger: "axis",
        axisPointer: {
          snap: true,
          lineStyle: {
            color: "#242e47",
            width: 2
          }
        },
        formatter: (params) => {
          let result = params[0].axisValueLabel + "<br/>";
          params.forEach(item => {
            let formattedValue = new Intl.NumberFormat(this.session.user.language === "en" ? 'en-IN' : 'es-ES', {
              maximumFractionDigits: this.fractionalLength
            }).format(Number(item.data[1]));

            result += `${item.marker} <strong>${item.seriesName}:</strong> ${formattedValue} ${(item.data[5] ?? '')} <br/>`;
          });

          return result;
        }
      };
      let i = 0;
      for (let variable in this.chartHistory["2"]) {
        chart2Options.yAxis[i] = {
          type: "value",
          axisLabel: {
            fontSize: 9,
            formatter: (value) => {
              return new Intl.NumberFormat(
                this.session.user.language === "en" ? "en-IN" : "es-ES",
                { maximumFractionDigits: Number(this.fractionalLength) }
              ).format(value) +
                ((this.chartHistory["2"][variable][i] !== undefined)
                  ? ` ${this.chartHistory["2"][variable][i][5]}`
                  : '');
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: this.colors[i]
            }
          },
          name: variable.substring(0, 3).toUpperCase(),
          nameTextStyle: {
            fontSize: 10
          },
          dataZoom: {
            yAxisIndex: 'none',
          },
          restore: {},
          offset: Math.floor(i / 2) * 25,
          position: (this.chartHistory["2"][variable][i] != undefined)
            ? this.chartHistory["2"][variable][i][2]
            : (i % 2 === 0)
              ? "left"
              : "right"
        }
        chart2Options.legend.data[i] = variable;
        chart2Options.series[i] = {
          data: this.chartHistory["2"][variable],
          name: variable,
          yAxisIndex: i,
          type: (
            this.chartHistory["2"][variable] &&
            this.chartHistory["2"][variable][i] &&
            this.chartHistory["2"][variable][i][4] !== undefined
          ) ? this.chartHistory["2"][variable][i][4] : "line",
          symbol: "emptyDiamond",
          symbolSize: 6,
          color: this.colors[i],
          smooth: false,
          step: (this.chartHistory["2"][variable][i] != undefined&& this.chartHistory["2"][variable][i][3]) ? 'end' : false,
          areaStyle: {
            color: this.colors[i],
            opacity: 0.03
          },
        }
        i += 1;
      }
      return chart2Options;
    }
  },
  emits: ["setCurrentCompany"],
  methods: {
    generateCSV(chartNumber) {
      const data = this.chartHistory[chartNumber];
      let csvContent = "activo,variable,valor,fecha\n";
      const formatearFecha = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hour = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
      };

      Object.keys(data).forEach((key) => {
        const entries = data[key];
        const entity = key.split('-')[1];
        Object.keys(entries).forEach((sensorName) => {
          const readings = entries[sensorName];
          const date = formatearFecha(new Date(readings[0]));
          csvContent += `"${entity}"${this.session.user.fieldSeparator}"${key}"${this.session.user.fieldSeparator}"${readings[1]}"${this.session.user.fieldSeparator}"${date}"\n`;
        });
      });
      const csvName = `${this.currentCompany.name}_data.csv`;
      this.downloadCSV(csvContent, csvName);
    },


    downloadCSV(csvContent, fileName) {
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({ params: { currentCompanyNifCif: value } })
      this.$emit('setCurrentCompany', value);
    },
    // GET
    async getDeviceData(callback) {
      this.selectVariablesEnabled = false
      let targetVariableLabel;
      if (this.targetVariableId) {
        for (let variable of Object.values(this.historyVariables)) {
          if (variable.variableId === this.targetVariableId) {
            targetVariableLabel = variable.label;
            break;
          }
        }
      }
      let response = await api.getDeviceData(this.targetDeviceId);
      this.deviceData = api.parseDeviceFromResponse(response);
      if (Object.keys(this.historyVariables).length > 0) {
        let found = false;
        for (let variable of Object.values(this.historyVariables)) {
          if (variable.label === targetVariableLabel) {
            this.targetVariableId = variable.variableId;
            found = true;
            break;
          }
        }
        if (!found) {
          this.targetVariableId = Object.values(this.historyVariables)[0].variableId;
        }
      } else {
        this.targetVariableId = "";
      }
      this.selectVariablesEnabled = true
      if (callback && typeof callback === "function") callback(this.deviceData);
    },
    async getDeviceListByCompany(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getDeviceListByCompany(request);
      this.availableDevices = api.parseDevicesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.availableDevices);
    },
    async getDeviceVariableHistory(n, requestData, callback) {
      let targetAxis = requestData ? requestData.targetAxis : (this.checkboxTargetAxisRight) ? 'right' : 'left';
      let step = requestData ? requestData.step : (this.checkboxStep) ? true : false;
      let chartType = requestData ? requestData.chartType : this.lineChart ? "line" : "bar"
      if (this.startDateTimeInput[n] === "" || this.endDateTimeInput[n] === "") {
        if (this.startDateTimeInput[n] === "")
          $("#inputStartDate").get()[0].classList.add("missing")
        if (this.endDateTimeInput === "")
          $("#inputEndDate").get()[0].classList.add("missing")
        setTimeout(() => {
          $("#inputStartDate").get()[0].classList.remove("missing")
          $("#inputEndDate").get()[0].classList.remove("missing")
        }, 3000);
        return;
      }
      if (this.deviceData != {}) {
        let request = {
          id: this.deviceData.id,
          variableLabel: this.historyVariables[this.targetVariableId].label,
          variableType: this.historyVariables[this.targetVariableId].type,
          limit: 0,
          offset: 0,
          startDateTime: api.parseDateTimeToUTCString(this.startDateTimeInput[n], this.session.user, this.$dayjs),
          endDateTime: api.parseDateTimeToUTCString(this.endDateTimeInput[n], this.session.user, this.$dayjs)
        }
        let response = await api.getDeviceVariableHistory(requestData ? requestData : request);
        // PARSE HISTORY RESPONSE
        let parsedResponse = api.parseVariableDataFromHistoryResponse(response);
        let rawHistory = parsedResponse.history
        let deviceName
        if (requestData) {
          this.chartHistory[n][requestData.variableLabel + "-" + requestData.entity] = [];
          for (let i in rawHistory) {
            this.chartHistory[n][requestData.variableLabel + "-" + requestData.entity][i] = [
              // DATE
              this.$dayjs(new Date(rawHistory[i][0])).utc("z").local().tz(this.session.user.dateTimeZone).$d,
              // VALUE
              new Number(rawHistory[i][1]).toFixed(this.fractionalLength),
              // POSITION
              targetAxis,
              // STEP
              step,
              chartType,
              parsedResponse.unit
            ]
          }

        } else {
          deviceName = this.targetDeviceId === this.deviceData.id ? this.deviceData.label : null
          this.chartHistory[n][this.historyVariables[this.targetVariableId].label + "-" + deviceName] = [];
          for (let i in rawHistory) {
            this.chartHistory[n][this.historyVariables[this.targetVariableId].label + "-" + deviceName][i] = [
              // DATE
              this.$dayjs(new Date(rawHistory[i][0])).utc("z").local().tz(this.session.user.dateTimeZone).$d,
              // VALUE
              new Number(rawHistory[i][1]).toFixed(this.fractionalLength),
              // POSITION
              targetAxis,
              // STEP
              step,
              chartType,
              parsedResponse.unit
            ]
          }
        }

        // SET DATA BOUNDRIES
        this.startDateTime[n] = this.startDateTimeInput[n];
        this.endDateTime[n] = this.endDateTimeInput[n];


        if (this.startDateTimeChart[n].length > 0) {
          const startDateTime = new Date(this.startDateTime[n]);
          const startDateTimeChart = new Date(this.startDateTimeChart[n]);
          if (startDateTime.getTime() < startDateTimeChart.getTime()) {
            this.startDateTimeChart[n] = this.startDateTime[n];
          }
        } else {
          this.startDateTimeChart[n] = this.startDateTime[n];
        }

        if (this.endDateTimeChart[n].length > 0) {
          const endDateTime = new Date(this.endDateTime[n]);
          const endDateTimeChart = new Date(this.endDateTimeChart[n]);
          if (endDateTime.getTime() > endDateTimeChart.getTime()) {
            this.endDateTimeChart[n] = this.endDateTime[n];
          }
        } else {
          this.endDateTimeChart[n] = this.endDateTime[n];
        }


        // SWITCH AXIS TARGET
        this.checkboxTargetAxisRight = !this.checkboxTargetAxisRight;
        this.checkboxTargetAxisLeft = !this.checkboxTargetAxisRight;
        if (callback && typeof callback === "function") callback(this.availableDevices)
      }
    },
    async getMachineListByCompany() {
      this.machineList = [];
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getMachineListByCompany(request);
      for (let i in response.maquinas) [
        this.machineList[i] = {
          id: response.maquinas[i].idDcl,
          label: response.maquinas[i].etiqueta,
        }
      ]
    },
    async applyChartUpdate(chartNumber) {
      const data = this.chartHistory[chartNumber];
      Object.keys(data).forEach(async (key) => {

        const [variable, ...zonaParts] = key.split('-');
        const entity = zonaParts.join('-');
        const machine = this.machineList.find(machine => machine.label === entity);
        const device = this.availableDevices.find(device => device.label === entity);
        const id = machine ? machine.id : (device ? device.id : null);
        if (id) {
          const firstEntry = (!data[key] || data[key].length === 0) ? "" : data[key][0];
          const position = (!data[key] || data[key].length === 0) ? 'left' : firstEntry[2];
          const step = (!data[key] || data[key].length === 0) ? 'false' : firstEntry[3]
          const chartType = (!data[key] || data[key].length === 0) ? 'line' : firstEntry[4]
          const request = {
            id: id,
            entity: entity,
            variableLabel: variable,
            variableType: this.historyVariables[this.targetVariableId].type,
            limit: 0,
            offset: 0,
            targetAxis: position,
            step: step,
            chartType: chartType,
            startDateTime: api.parseDateTimeToUTCString(
              this.startDateTimeInput[chartNumber],
              this.session.user,
              this.$dayjs
            ),
            endDateTime: api.parseDateTimeToUTCString(
              this.endDateTimeInput[chartNumber],
              this.session.user,
              this.$dayjs
            )
          };

          setTimeout(machine ? await this.getMachineVariableHistory(chartNumber, request) : await this.getDeviceVariableHistory(chartNumber, request), 200);
        } else {
          console.warn(`No se encontró un dispositivo o máquina con el label ${entity}`);
        }

      });
    },
    async getMachineData(callback) {
      let targetVariableLabel;
      if (this.targetVariableId) {
        for (let variable of Object.values(this.historyVariables)) {
          if (variable.variableId === this.targetVariableId) {
            targetVariableLabel = variable.label;
            break;
          }
        }
      }
      let response = await api.getMachineData(this.targetDeviceId);
      this.machineData = api.parseMachineFromResponse(response);
      if (Object.keys(this.historyVariables).length > 0) {
        let found = false;
        for (let variable of Object.values(this.historyVariables)) {
          if (variable.label === targetVariableLabel) {
            this.targetVariableId = variable.variableId;
            found = true;
            break;
          }
        }
        if (!found) {
          this.targetVariableId = Object.values(this.historyVariables)[0].variableId;
        }
      } else {
        this.targetVariableId = "";
      }
      if (callback && typeof callback === "function") callback(this.targetMachineData);
    },
    async getMachineVariableHistory(n, requestData, callback) {
      let targetAxis = requestData ? requestData.targetAxis : (this.checkboxTargetAxisRight) ? 'right' : 'left';
      let step = requestData ? requestData.step : (this.checkboxStep) ? true : false;
      let chartType = requestData ? requestData.chartType : this.lineChart ? "line" : "bar"
      if (this.startDateTimeInput[n] === "" || this.endDateTimeInput[n] === "") {
        if (this.startDateTimeInput[n] === "")
          $("#inputStartDate").get()[0].classList.add("missing")
        if (this.endDateTimeInput === "")
          $("#inputEndDate").get()[0].classList.add("missing")
        setTimeout(() => {
          $("#inputStartDate").get()[0].classList.remove("missing")
          $("#inputEndDate").get()[0].classList.remove("missing")
        }, 3000);
        return;
      }
      if (this.machineData != {}) {
        let request = {
          id: this.machineData.id,
          variableLabel: this.historyVariables[this.targetVariableId].label,
          variableType: this.historyVariables[this.targetVariableId].type,
          limit: 0,
          offset: 0,
          startDateTime: api.parseDateTimeToUTCString(this.startDateTimeInput[n], this.session.user, this.$dayjs),
          endDateTime: api.parseDateTimeToUTCString(this.endDateTimeInput[n], this.session.user, this.$dayjs)
        }
        let response = await api.getMachineVariableHistory(requestData ? requestData : request);
        // PARSE HISTORY RESPONSE
        // PARSE HISTORY RESPONSE
        let parsedResponse = api.parseVariableDataFromHistoryResponse(response);
        let rawHistory = parsedResponse.history
        let machineName
        if (requestData) {
          this.chartHistory[n][requestData.variableLabel + "-" + requestData.entity] = [];
          for (let i in rawHistory) {
            this.chartHistory[n][requestData.variableLabel + "-" + requestData.entity][i] = [
              // DATE
              this.$dayjs(new Date(rawHistory[i][0])).utc("z").local().tz(this.session.user.dateTimeZone).$d,
              // VALUE
              new Number(rawHistory[i][1]).toFixed(this.fractionalLength),
              // POSITION
              targetAxis,
              // STEP
              step,
              chartType,
              parsedResponse.unit
            ]
          }

        } else {
          machineName = this.targetDeviceId === this.machineData.id ? this.machineData.label : null
          this.chartHistory[n][this.historyVariables[this.targetVariableId].label + "-" + machineName] = [];
          for (let i in rawHistory) {
            this.chartHistory[n][this.historyVariables[this.targetVariableId].label + "-" + machineName][i] = [
              // DATE
              this.$dayjs(new Date(rawHistory[i][0])).utc("z").local().tz(this.session.user.dateTimeZone).$d,
              // VALUE
              new Number(rawHistory[i][1]).toFixed(this.fractionalLength),
              // POSITION
              targetAxis,
              // STEP
              step,
              chartType,
              parsedResponse.unit
            ]
          }
        }
        // SET DATA BOUNDRIES
        this.startDateTime[n] = this.startDateTimeInput[n];
        this.endDateTime[n] = this.endDateTimeInput[n];

        if (this.startDateTimeChart[n].length > 0) {
          const startDateTime = new Date(this.startDateTime[n]);
          const startDateTimeChart = new Date(this.startDateTimeChart[n]);
          if (startDateTime.getTime() < startDateTimeChart.getTime()) {
            this.startDateTimeChart[n] = this.startDateTime[n];
          }
        } else {
          this.startDateTimeChart[n] = this.startDateTime[n];
        }

        if (this.endDateTimeChart[n].length > 0) {
          const endDateTime = new Date(this.endDateTime[n]);
          const endDateTimeChart = new Date(this.endDateTimeChart[n]);
          if (endDateTime.getTime() > endDateTimeChart.getTime()) {
            this.endDateTimeChart[n] = this.endDateTime[n];
          }
        } else {
          this.endDateTimeChart[n] = this.endDateTime[n];
        }
        // SWITCH AXIS TARGET
        this.checkboxTargetAxisRight = !this.checkboxTargetAxisRight;
        this.checkboxTargetAxisLeft = !this.checkboxTargetAxisRight;
        if (callback && typeof callback === "function") callback(this.machineList)
      }
    },
    getModulesFromUrl() {
      try {
        switch (true) {
          case this.$route.params.currentModule.includes("smartLockers"):
            return 1;
          case this.$route.params.currentModule.includes("devices"):
            return 2;

          case this.$route.params.currentModule.includes("fabric"):
            return 1;
          case this.$route.params.currentModule.includes("electricalMonitoring"):
            return 2;

          default:
            return null;
        }
      } catch (error) {
        console.error("Error parsing URL:", error);
        return null;
      }

    },
    async analyzeUrl() {
      this.availableDevices = [];
      this.machineList = [];
      let moduleInUrl = this.getModulesFromUrl()
      if (this.moduleAvailable != moduleInUrl) this.moduleAvailable = moduleInUrl
      if (this.moduleAvailable === 2) {
        await this.getDeviceListByCompany();
        if (this.availableDevices[0] != undefined) {
          this.targetDeviceId = this.availableDevices[0].id;
          await this.getDeviceData();
        }
        else {
          this.targetDeviceId = ""
        }
      } else if (this.moduleAvailable === 1) {
        await this.getMachineListByCompany()
        if (this.machineList[0] != undefined) {
          this.targetDeviceId = this.machineList[0].id;
          await this.getMachineData();
        }
        else {
          this.targetDeviceId = ""
        }
      }
      if (Object.entries(this.historyVariables)[0] != undefined) {
        this.targetVariableId = Object.entries(this.historyVariables)[0][0];
      }
      else {
        this.targetVariableId = ""
      }
    }
  },
  async created() {
    await this.analyzeUrl()
  }
}
</script>

<style>
#cardRelaysChartsContainer {
  display: flex;
  gap: 1rem;
}

#cardDigitalInputsContainer {
  display: flex;
  gap: 1rem;
}

.btnSizeChart:hover {
  color: var(--main-dark) !important;
}

.dateInput {
  color-scheme: dark;
}

.dateInput:active {
  color-scheme: light;
}

@media (max-width: 992px) {

  #cardOverall,
  #cardCurrentCharts,
  #cardSCC {
    padding-right: 1rem !important;
  }

  #cardRelaysChartsContainer,
  #cardDigitalInputsContainer {
    display: block;
  }
}
</style>