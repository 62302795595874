<template>
<!-- MODAL Prepare Reception -->
  <div id="prepareReceptionModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 section-container rounded-5 sombra-modal">
<!-- HEAD -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="arrow-redo-outline" class="tx-18 me-2 align-bottom"></ion-icon>
            {{dictionary["Preparación"][session.user.language]}}
            <span class="tx-13 tx-light"> {{ (isProcessed===undefined)?"/ "+dictionary["Sin procesar"][session.user.language]:(!isProcessed)?"/ "+dictionary["Procesando"][session.user.language]:'' }} </span>
          </h6>
        </div>

        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('togglePrepareReceptionModal')">	
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

<!-- CONTENT Prepare Reception -->
      <div class="bg-caja m-0 p-0 w-100 rounded-bottom-5 pe-3">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;" class="p-3">
          <div class="col-12 p-3 border rounded form-material" style="overflow: auto; overflow-y: hidden; display: flex; flex-flow: wrap;">

            <div v-if="receptionData.params != undefined" id="receptionCheckListContainer" class="w-100">
            
              <div class="col-12 tx-bold tx-left text-nowrap m-0 p-0">
                <span class="tx-clomos-dark">
                  {{dictionary['Recepción'][session.user.language] + ": "}}
                </span>
                <span>
                  {{ this.receptionData.id }}
                </span>
              </div>

              <div class="col-12 m-0 p-0">
                <hr class="tx-clomos-dark">
              </div>

              <table id="receptionCheckList" cellspacing="0" class="col-12 m-0 p-0">
                <tr class="border-bottom">
                  <td class="tx-bold gap-1" style="display:flex; flex-direction:row; justify-content: space-between;">
                    <div v-show="receptionData.status==='1'||receptionData.status==='2'"
                    class="bg-clomos rounded-3 tx-white m-0" style="margin:auto; cursor:pointer; width: 30px; height: 30px; padding: 5px 5px;"
                    :class="(isRequesting)?'opacity-50':''"
                    @click="(!isRequesting)?getReceptionData():''">
                      <ion-icon name="reload-outline" class="loader tx-20 tx-white align-bottom" :class="(isRequesting===true)?'requesting':''"></ion-icon>
                    </div>
                    <span class="m-auto pt-1"> {{dictionary['Identificador'][session.user.language]}} </span>
                  </td>
                  <td class="tx-bold"> {{dictionary['Nombre'][session.user.language]}} </td>
                  <!--
                  <td class="tx-bold"> {{dictionary['Talla'][session.user.language]}} </td>
                  <td class="tx-bold"> {{dictionary['Color'][session.user.language]}} </td>
                -->
                  <td class="tx-bold"> {{dictionary['Caducidad'][session.user.language]}} </td>
                  <td class="tx-bold"> {{dictionary['Zona'][session.user.language]}} </td>
                  <td class="tx-bold"> {{dictionary['Unidades'][session.user.language]}} </td>
                  
                  <td class="tx-bold text-nowrap"> {{dictionary['Encontrados'][session.user.language] +' / '+ dictionary['Procesados'][session.user.language] +' / '+ dictionary['Hueco'][session.user.language]}} </td>
                  
                  <td class="tx-bold" v-show="receptionData.status==='1'||receptionData.status==='2'"> {{dictionary['Editar'][session.user.language]}} </td>
                </tr>
                
                <tr class="border-bottom" v-for="product,index of receptionData.params['productos']" :key="product" :id="'rowNumber'+index+'For'+product.id">
                  
                  <td class="text-nowrap p-1"> 
                    {{ (product.id!=undefined)?product.id:'-' }} 
                  </td>

                  <td class="text-nowrap p-1"> 
                    {{ (product.etiqueta!=undefined)?product.etiqueta:'-' }}
                  </td>

                  <!--
                  <td class="text-nowrap p-1"> 
                    {{ (product.talla!=undefined)?product.talla:'-' }}
                  </td>
                  <td class="text-nowrap p-1"> 
                    {{ (product.color!=undefined)?product.color:'-' }}
                  </td>
                  -->
                  <td class="text-nowrap p-1"> 
                    {{ (product.fechaCaducidad!=undefined)?product.fechaCaducidad:'-' }}
                  </td>

                  <td class="text-nowrap p-1"> 
                    <span v-show="(product.zona==='automatico')"> Automático </span>
                    <span v-show="(product.zona==='locker')"> Locker </span>
                    <span v-show="(product.zona==='libre')"> Libre </span>
                    <span v-show="(product.zona!=undefined && product.zona!='automatico' && product.zona!='locker' && product.zona!='libre')"> {{product.zona}} </span>
                    <span v-show="(product.zona===undefined)"> - </span>
                  </td>

                  <td class="text-nowrap p-1"> 
                    {{ (product.unidades!=undefined)?product.unidades:'-' }}
                  </td>

                  <td class="m-0 p-0 border-start">
                    <div v-if="receptionData.params['procesadosRaw']!=undefined
                    && receptionData.params['procesadosRaw'][product.id]!=undefined">
                      <div v-for="productRaw,raw of receptionData.params['procesadosRaw'][product.id]" :key="productRaw"
                      class="m-0 p-0">
                        <tr v-show="(product.lote === undefined || productRaw.lote == product.lote)
                        && (product.numeroSerie === undefined || productRaw.numeroSerie == product.numeroSerie)
                        && (product.fechaCaducidad === undefined || productRaw.fechaCaducidad == product.fechaCaducidad)
                        && (product.talla === undefined || productRaw.talla == product.talla)
                        && (product.color === undefined || productRaw.color == product.color)"
                        class="text-nowrap h-100 p-1 border-bottom align-middle">
                          <td
                          :class="((receptionData.status==='1'||receptionData.status==='2') && product.zona==='libre')?'':'opacity-50'"
                          class="text-nowrap p-0"> 
                            <div class="p-1"
                            @click="((receptionData.status==='1'||receptionData.status==='2') && product.zona==='libre')?removeRaw(productRaw,raw):''"
                            style="margin:auto; cursor:pointer; padding:auto;">
                              <ion-icon name="remove-circle-outline" class="tx-32 tx-clomos-dark align-middle"></ion-icon>
                            </div>
                          </td>

                          <td class="text-nowrap" style="width:30%; min-width:110px; height:30px; vertical-align:middle;">
                            <span class="m-auto"> 
                              {{ (productRaw!=undefined&&productRaw.unidadesProcesables!=undefined&&productRaw.unidadesProcesables!="")?productRaw.unidadesProcesables:"-" }} 
                            </span>
                          </td>

                          <td class="text-nowrap" style="width:30%; min-width:110px; vertical-align:middle;">
                            <span class="m-auto" :class="(processedOk(product)===false)?'tx-danger':''"> 
                              {{ (productRaw!=undefined&&processedOk(product)!=undefined)?(productRaw!=undefined&&processedOk(product)===false)?productRaw.unidades+' '+product.unidad+' ⚠':productRaw.unidades+' '+product.unidad:"-" }} 
                            </span>
                            <div class="m-0 p-0">
                              <input v-show="(product.zona==='locker'||product.zona==='libre') && (receptionData.status==='1'||receptionData.status==='2')"
                              :disabled="(receptionData.status!='1'&&receptionData.status!='2')"
                              :id="'inputProcessedUnits'+raw+'For'+product.id" value="" :placeholder="product.unidad" type="number" min=0 class="w-100 tx-13 border-1 p-1 rounded-3 m-auto text-center" style="color:black">
                            </div>
                          </td>

                          <td class="text-nowrap" style="width:30%; min-width:110px; height:30px; vertical-align:middle;"> 
                            <span class="m-auto">
                              {{ processedSlot(product,raw) }}
                            </span>
                            <select v-show="(receptionData.status==='1'||receptionData.status==='2') && product.zona==='libre'" :id="'selectSlotId'+raw+'For'+product.id" value="A1" 
                            :disabled="(receptionData.status!='1'&&receptionData.status!='2')"
                            class="form-control w-100 m-auto" style="color:black"> 
                              <option v-for="slot in freeSlotList" :key="slot" :value="slot.id"> {{slot.label}} </option>
                            </select>
                          </td>
                        </tr>
                      </div>
                    </div>

                    <div
                    :class="((receptionData.status==='1'||receptionData.status==='2') && product.zona==='libre')?'':'opacity-50'"
                    class="p-1" style="margin:auto; cursor:pointer;">
                      <ion-icon @click="((receptionData.status==='1'||receptionData.status==='2') && product.zona==='libre')?addRaw(product):''" name="add-circle-outline" class="tx-32 tx-clomos-dark align-middle"></ion-icon>
                    </div>
                    
                  </td>

                  <td class="p-2 border-start"
                  v-show="(receptionData.status==='1'||receptionData.status==='2')">
                    <div :class="((product.zona==='locker'||product.zona==='libre')
                    &&receptionData.params['procesadosRaw']!=undefined
                    &&receptionData.params['procesadosRaw'][product.id]!=undefined
                    &&receptionData.params['procesadosRaw'][product.id].length>0)?'':'opacity-50'"
                    @click="((product.zona==='locker'||product.zona==='libre')
                    &&receptionData.params['procesadosRaw']!=undefined
                    &&receptionData.params['procesadosRaw'][product.id]!=undefined
                    &&receptionData.params['procesadosRaw'][product.id].length>0)?process(product,index):''"
                    class="bg-clomos rounded-3 tx-white m-auto"
                    style="width:30px; height:30px; margin:auto; cursor:pointer; padding:auto; padding: 3px 0 0 2px;">
                      <ion-icon name="create-outline" class="tx-20 tx-white align-middle"></ion-icon>
                    </div>                    
                  </td>

                </tr>

              </table>

            </div>

            <div v-show="receptionData.status==='1'||receptionData.status==='2'" class="col-12 m-0 p-0">
              <hr class="tx-clomos-dark">
            </div>
            
            <div v-show="receptionData.status==='1'||receptionData.status==='2'" class="w-100 m-0 p-0 row">

<!-- SUBMIT -->
              <button type="button" id="btnSubmitReception" class="col-lg-7 col-12 button-ok gradient-tablas rounded-3"
              :class="(isProcessed)?'btn-clomos':'btn-clomos-disabled'"
              @click="this.prepareReception()">
              {{dictionary['Preparar'][session.user.language]}}
              </button>

              <div class="col-lg-1 col-12 p-2"></div>

              <button type="button" id="btnCancelReception" class="col-lg-3 col-12 ms-auto button-ok gradient-tablas rounded-3"
              :class="(!isProcessed)?'btn-clomos':'btn-clomos-disabled'"
              @click="this.cancelReception()">
              {{dictionary['Cancelar'][session.user.language]}}
              </button>

            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "ReceptionsComponent",
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    },
    receptionId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      receptionData: {},
      freeSlotList: [],
      lockerSlotList: [],
      bulgeModel: {
        boxType: "",
        volume: {
          height: 0,
          width: 0,
          depth: 0
        },
      },
      isRequesting: false
    }
  },
  emits: ["togglePrepareReceptionModal", "setCurrentCompany"],
  computed: {
    isProcessed() {
      let isProcessed = undefined;
      if (this.receptionData.params != undefined && this.receptionData.params["procesadosRaw"] != undefined) {
        isProcessed = true;
        for (let i in this.receptionData.params['productos']) {
          if (Number(this.receptionData.params['productos'][i].unidades) != this.processedUnits(this.receptionData.params['productos'][i])) {
            isProcessed = false;
          }
        }
      }
      return isProcessed;
    }
  },
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    processedOk(product) {
      let ok = undefined;
      let n = 0; 
      if (this.receptionData.params["procesadosRaw"] != undefined) {
        for (let i in this.receptionData.params["procesadosRaw"]) {
          for (let r in this.receptionData.params["procesadosRaw"][i]) {
            if (this.receptionData.params["procesadosRaw"][i][r]!=null 
            && this.receptionData.params["procesadosRaw"][i][r]!=undefined 
            && this.receptionData.params["procesadosRaw"][i][r].id == product.id
            && (product.lote === undefined || this.receptionData.params["procesadosRaw"][i][r].lote == product.lote)
            && (product.numeroSerie === undefined || this.receptionData.params["procesadosRaw"][i][r].numeroSerie == product.numeroSerie)
            && (product.fechaCaducidad === undefined || this.receptionData.params["procesadosRaw"][i][r].fechaCaducidad == product.fechaCaducidad)
            && (product.talla === undefined || this.receptionData.params["procesadosRaw"][i][r].talla == product.talla)
            && (product.color === undefined || this.receptionData.params["procesadosRaw"][i][r].color == product.color)) {
              ok = false;
              n += Number(this.receptionData.params["procesadosRaw"][i][r].unidades);
            }
          }
        }
      }
      if (Number(product.unidades) === n)
        ok = true;
      return ok;
    },
    processedUnits(product) {
      let units = 0;
      if (this.receptionData.params["procesadosRaw"] != undefined) {
        for (let i in this.receptionData.params["procesadosRaw"]) {
          for (let r in this.receptionData.params["procesadosRaw"][i]) {
            if (this.receptionData.params["procesadosRaw"][i][r]!=null 
            && this.receptionData.params["procesadosRaw"][i][r]!=undefined 
            && this.receptionData.params["procesadosRaw"][i][r].id == product.id
            && (product.lote === undefined || this.receptionData.params["procesadosRaw"][i][r].lote == product.lote)
            && (product.numeroSerie === undefined || this.receptionData.params["procesadosRaw"][i][r].numeroSerie == product.numeroSerie)
            && (product.fechaCaducidad === undefined || this.receptionData.params["procesadosRaw"][i][r].fechaCaducidad == product.fechaCaducidad)
            && (product.talla === undefined || this.receptionData.params["procesadosRaw"][i][r].talla == product.talla)
            && (product.color === undefined || this.receptionData.params["procesadosRaw"][i][r].color == product.color)) {
              units += this.receptionData.params["procesadosRaw"][i][r].unidades;
            }
          }
        }
      }
      return units;
    },
    processedSlot(product,r) {
      let slot = "-";
      if (this.receptionData.params["procesadosRaw"] != undefined) {
        for (let i in this.receptionData.params["procesadosRaw"]) {
          if (this.receptionData.params["procesadosRaw"][i][r]!=null 
          && this.receptionData.params["procesadosRaw"][i][r]!=undefined 
          && this.receptionData.params["procesadosRaw"][i][r].id == product.id
          && (product.lote === undefined || this.receptionData.params["procesadosRaw"][i][r].lote == product.lote)
          && (product.numeroSerie === undefined || this.receptionData.params["procesadosRaw"][i][r].numeroSerie == product.numeroSerie)
          && (product.fechaCaducidad === undefined || this.receptionData.params["procesadosRaw"][i][r].fechaCaducidad == product.fechaCaducidad)
          && (product.talla === undefined || this.receptionData.params["procesadosRaw"][i][r].talla == product.talla)
          && (product.color === undefined || this.receptionData.params["procesadosRaw"][i][r].color == product.color)
          && (this.receptionData.params["procesadosRaw"][i][r].idHueco != undefined && this.receptionData.params["procesadosRaw"][i][r].idHueco != "")) {
            for (slot in this.lockerSlotList) {
              if (this.lockerSlotList[slot].id === this.receptionData.params["procesadosRaw"][i][r].idHueco) {
                return this.lockerSlotList[slot].label;
              }
            }
            for (slot in this.freeSlotList) {
              if (this.freeSlotList[slot].id === this.receptionData.params["procesadosRaw"][i][r].idHueco) {
                return this.freeSlotList[slot].label;
              }
            }
            for (slot in this.autoSlotList) {
              if (this.autoSlotList[slot].id === this.receptionData.params["procesadosRaw"][i][r].idHueco) {
                return this.autoSlotList[slot].label;
              }
            }
            return this.receptionData.params["procesadosRaw"][i][r].idHueco;
          }
        }
      }
      return slot;
    },
    // GET
    async getReceptionData(callback) {
      this.isRequesting = true;
      let response = await api.getOrderData(this.receptionId);
      this.receptionData = api.parseOrderFromResponse(response);
      this.isRequesting = false;
      this.receptionData.userId = this.session.user.id;
      await this.getAvailableSlots();
      if (callback && typeof callback === "function") callback(this.receptionData);
    },
    async getAvailableSlots() {
      this.lockerSlotList = [];
      this.freeSlotList = [];
      this.autoSlotList = [];
      let machineListRequest = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      }
      let machineListResponse = await api.getMachineListByCompany(machineListRequest);
      let parsedMachineList = api.parseMachinesFromResponse(machineListResponse);
      for (let i in parsedMachineList) {
        let slotListRequest = {
          machineId: parsedMachineList[i].id,
          limit: 0,
          offset: 0,
          where: ""
        }
        let slotListResponse = await api.getBundleListByMachine(slotListRequest);
        let parsedSlotListResponse = api.parseBundlesFromResponse(slotListResponse);
        for (let ii in parsedSlotListResponse) {
          if (parsedMachineList[i].dclTypeId === "locker_frio") this.lockerSlotList.push(parsedSlotListResponse[ii]);
          if (parsedMachineList[i].dclTypeId === "zona_libre") this.freeSlotList.push(parsedSlotListResponse[ii]);
          if (parsedMachineList[i].dclTypeId === "zona_automatico") this.autoSlotList.push(parsedSlotListResponse[ii]);
        }
      }
    },
    // SET
    addRaw(product) {
      if (this.receptionData.params['procesadosRaw'] === undefined)
        this.receptionData.params['procesadosRaw'] = {};
      if (this.receptionData.params['procesadosRaw'][product.id] === undefined) 
        this.receptionData.params['procesadosRaw'][product.id] = [];
      let raw = {
        id: (product.id!=undefined)?product.id:"",
        zona: (product.zona!=undefined)?product.zona:"",
        lote: (product.lote!=undefined)?product.lote:"",
        numeroSerie: (product.numeroSerie!=undefined)?product.numeroSerie:"",
        fechaCaducidad: (product.fechaCaducidad!=undefined)?product.fechaCaducidad:"",
        talla: (product.talla!=undefined)?product.talla:"",
        color: (product.color!=undefined)?product.color:"",
        unidad: (product.unidad!=undefined)?product.unidad:"",
        unidadesPedidas: (product.unidades!=undefined)?product.unidades:"",
        unidadesProcesables: "",
        unidades: 0,
        idHueco: "",
      }
      this.receptionData.params['procesadosRaw'][product.id].push(raw);
    },
    removeRaw(product, raw) {
      if (product.zona!='locker' && product.zona!='libre') return;
      if (this.receptionData.params['procesadosRaw'][product.id][raw] != undefined) {
        this.receptionData.params['procesadosRaw'][product.id].splice(raw,1);
      }
    },
    async process(product,index) {
      // Procesados Raw
      if (this.receptionData.params['procesadosRaw'] === undefined)
        this.receptionData.params['procesadosRaw'] = {};
      if (this.receptionData.params['procesadosRaw'][product.id] === undefined) {
        this.receptionData.params['procesadosRaw'][product.id] = [];
        let raw = {
          id: product.id,
          zona: product.zona,
          lote: product.lote,
          numeroSerie: product.numeroSerie,
          fechaCaducidad: product.fechaCaducidad,
          talla: product.talla,
          color: product.color,
          unidadesPedidas: product.unidades,
          unidadesProcesables: ""
        }
        this.receptionData.params['procesadosRaw'][product.id].push(raw);
        for (let rawIndex in this.receptionData.params['procesadosRaw'][product.id]) {
          if (product.zona==='libre')
            this.receptionData.params['procesadosRaw'][product.id][rawIndex].idHueco = $("#selectSlotId"+(Number(rawIndex)+Number(index))+"For"+product.id).get()[0].value;
          if (product.zona==='locker'||product.zona==='libre')
            if ($("#inputProcessedUnits"+(Number(rawIndex)+Number(index))+"For"+product.id).get()[0].value != "")
              this.receptionData.params['procesadosRaw'][product.id][rawIndex].unidades = Number($("#inputProcessedUnits"+(Number(rawIndex)+Number(index))+"For"+product.id).get()[0].value);
        }
      }
      else {
        for (let rawIndex in this.receptionData.params['procesadosRaw'][product.id]) {
          if (this.receptionData.params['procesadosRaw'][product.id][rawIndex] === null )
            this.receptionData.params['procesadosRaw'][product.id].splice(rawIndex,1);
          else if (this.receptionData.params['procesadosRaw'][product.id][rawIndex].id === product.id
          && (product.lote === undefined || this.receptionData.params["procesadosRaw"][product.id][rawIndex].lote == product.lote)
          && (product.numeroSerie === undefined || this.receptionData.params["procesadosRaw"][product.id][rawIndex].numeroSerie == product.numeroSerie)
          && (product.fechaCaducidad === undefined || this.receptionData.params["procesadosRaw"][product.id][rawIndex].fechaCaducidad == product.fechaCaducidad)
          && (product.talla === undefined || this.receptionData.params["procesadosRaw"][product.id][rawIndex].talla == product.talla)
          && (product.color === undefined || this.receptionData.params["procesadosRaw"][product.id][rawIndex].color == product.color)) {
            if (product.zona==='libre') 
              this.receptionData.params['procesadosRaw'][product.id][rawIndex].idHueco = $("#selectSlotId"+(Number(rawIndex)+Number(index))+"For"+product.id).get()[0].value;
              if (product.zona==='locker'||product.zona==='libre')
              if ($("#inputProcessedUnits"+(Number(rawIndex)+Number(index))+"For"+product.id).get()[0].value != "")
                this.receptionData.params['procesadosRaw'][product.id][rawIndex].unidades = Number($("#inputProcessedUnits"+(Number(rawIndex)+Number(index))+"For"+product.id).get()[0].value);
          }
        }
      }
      let orderData = {
        id: this.receptionData.id,
        userId: this.session.user.id,
        params: this.receptionData.params,
      };
      let response = await api.setOrderData(orderData);
      if (response["status-code"] === "200") {
        window.alert("El producto ha sido procesado satisfactoriamente.");
      }
      else if (response["status-code"] === "304") {
        window.alert("El producto no ha sido modificado.");
      }
      else {
        window.alert("Error al procesar el producto.");
      }
    },
    async prepareReception() {
      // Procesados
      if (this.receptionData.params['procesados'] === undefined)
        this.receptionData.params['procesados'] = {};
      for (let productId in this.receptionData.params['procesadosRaw']) {
        let entry = this.receptionData.params['procesadosRaw'][productId];
        for (let rawIndex in entry) {
          let productRaw = entry[rawIndex];
          let dominantKey;
          if (productRaw.zona==="locker" || productRaw.zona==="libre") {
            if (this.receptionData.params['procesados'][productRaw.idStock]!=undefined) 
              dominantKey = productRaw.idStock;
            else
              dominantKey = String(Object.keys(this.receptionData.params['procesados']).length);
            if (this.receptionData.params['procesados'][dominantKey] === undefined) {
              this.receptionData.params['procesados'][dominantKey] = {
                id: productRaw.id,
                unidades: productRaw.unidades, 
                fechaEntrada: api.parseDateTimeToString(Date.now()),
                lote: (productRaw.lote!=undefined)?productRaw.lote:"",
                numeroSerie: (productRaw.numeroSerie!=undefined)?productRaw.numeroSerie:"",
                fechaCaducidad: (productRaw.fechaCaducidad!=undefined)?productRaw.fechaCaducidad:"",
                talla: (productRaw.talla!=undefined)?productRaw.talla:"",
                color: (productRaw.color!=undefined)?productRaw.color:"",
                idHueco: (productRaw.idHueco!=undefined)?productRaw.idHueco:"", 
                idStock: (productRaw.idStock!=undefined)?productRaw.idStock:"", 
              }
            }
            else {
              this.receptionData.params['procesados'][dominantKey].unidades += productRaw.unidades;
            }
          }
        }
      }
      let orderData = {
        id: this.receptionData.id,
        userId: this.session.user.id,
        params: this.receptionData.params,
      };
      let processResponse = await api.setOrderData(orderData);
      if (processResponse["status-code"] === "200") {
        let statusData = {
          orderId : this.receptionId,
          dateTime : new Date(),
          status : 3
        }
        let prepareResponse = await api.setOrderStatus(statusData);
        if (prepareResponse["status-code"] === "200") {
          window.alert("La recepción ha sido preparada satisfactoriamente.");
          this.$emit('togglePrepareReceptionModal');
        }
        else {
          window.alert("Error al preparar la recepción.");
        }
      }
      else {
        window.alert("Error al procesar el producto.");
      }
      
    },
    // CANCEL
    async cancelReception() {
      if (this.receptionData.status == 2) {
        let statusData = {
          orderId : this.receptionId,
          dateTime : new Date(),
          status : 5
        }
        let response = await api.setOrderStatus(statusData);
        if (response["status-code"] === "200") {
          window.alert("La recepción ha sido cancelada satisfactoriamente.");
          this.$emit('togglePrepareReceptionModal');
        }
        else {
          window.alert("Error al cancelar la recepción.");
        }
      }
    }
  },
  async created() {
    await this.getReceptionData();    
    if (this.receptionData.status == 1) {
      let statusData = {
        orderId : this.receptionId,
        dateTime : new Date(),
        status : 2
      }
      let response = await api.setOrderStatus(statusData);
        if (response["status-code"] === "200") {
          window.alert("La recepción está siendo ejecutada.");
        }
    }
  }
}
</script>

<style>
#prepareReceptionModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.inputAmmountPerBulge {
  color:black;
  background:none;
  min-width:65px;
  max-width:65px;
  padding: 3px 15px 1px 1px;
}

.inputAmmountPerBulge.missing {
  box-shadow: inset 0px 0px 0px 2px #ce7d0a;
}

.inputAmmountPerBulge::-webkit-outer-spin-button,
.inputAmmountPerBulge::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inputAmmountPerBulge[type=number] {
  -moz-appearance: textfield;
}

.order-button {
  color: var(--main-dark) !important;
}

.order-button:hover {
  color: var(--main-dark-50) !important;
}

.order-button-disabled {
  cursor: none;
  color: var(--main-dark-50) !important;
  pointer-events: none
  ;
}

.loader {
  color: white !important;
  visibility: visible;
  transition: all 0.3s;
}

.requesting {
  animation: spin 3s infinite linear;
  transition: all 0.3s;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>