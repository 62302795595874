<template>
  <!-- MODAL New Machine -->
  <div id="newOrderModal" class="col-12 justify-content-center" style="padding: 15vh 20%">
    <div class="col-12 m-0 p-0 section-container rounded-5 shadow">

      <!-- HEADER -->
      <!-- HEAD -->
      <div class="row overflow-hidden col-12 m-0 p-0 tx-clomos-dark rounded-top-5 pb-1 h-100 gradient-tablas">
        <div class="col-10">
          <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
            <ion-icon name="document-outline" class="tx-18 me-2 align-bottom"></ion-icon>
            {{ dictionary["Nuevo informe"][session.user.language] }}
          </h6>
        </div>
        <div class="col-2 m-0 p-0 d-flex justify-content-end btn" @click="$emit('toggleNewOrderModal')">
          <ion-icon name="close" class="ms-auto tx-30 pt-1 pe-1 tx-white align-bottom"></ion-icon>
        </div>
      </div>

      <!-- CONTENT -->
      <div class="modal-body bg-light">
        <!-- STEPS INDICATOR -->
        <ul class="steps steps-counter steps-vertical">
          <li :class="['step-item', step >= 1 && 'active']">{{ dictionary["Datos básicos"][session.user.language] }}</li>
          <li :class="['step-item', step >= 2 && 'active']">{{ dictionary["Secciones"][session.user.language] }}</li>
          <li :class="['step-item', step >= 3 && 'active']">{{ dictionary["Notificaciones"][session.user.language] }}</li>
          <li :class="['step-item', step >= 4 && 'active']">{{ dictionary["Salida"][session.user.language] }}</li>
          <li :class="['step-item', step >= 5 && 'active']">{{ dictionary["Programación"][session.user.language] }}</li>
          <li :class="['step-item', step === 6 && 'active']">{{ dictionary["Resumen"][session.user.language] }}</li>
        </ul>

        <!-- STEP 1: BASIC INFO -->
        <div v-if="step === 1" class="step-content">
          <div class="row g-3">
            <div class="col-md-6">
              <label class="form-label">{{ dictionary["Nombre"][session.user.language] }} *</label>
              <input v-model="newOrderData.label" type="text" class="form-control text-dark" required>
            </div>
            <div class="col-md-6">
              <label class="form-label">{{ dictionary["Descripción"][session.user.language] }} *</label>
              <input v-model="newOrderData.description" type="text" class="form-control text-dark" required>
            </div>
          </div>
        </div>

        <!-- STEP 2: SECTIONS -->
        <div v-if="step === 2" class="step-content">
          <div v-for="(section, index) in newOrderData.sections" :key="index" class="card mb-3">
            <div class="card-body position-relative">              
              <button @click="removeSection(index)" class="btn-close position-absolute top-0 end-0 m-2"
                style="z-index: 10;"></button>

              <div class="row g-3">
                <div class="col-md-6">
                  <label class="form-label"> {{ dictionary["Nombre de sección"][session.user.language] }}*</label>
                  <input v-model="section.label" type="text" class="form-control text-dark" required>
                </div>
                <div class="col-md-6">
                  <label class="form-label">{{ dictionary["Descripción"][session.user.language] }}</label>
                  <input v-model="section.description" type="text" class="form-control text-dark">
                </div>
    
         <!--CAMBIO DEL SELECT -->
                <div v-if="section.label.length > 0" class="d-flex">
                   <div class="col-md-6">
                      <label class="form-label">{{ dictionary["Dispositivo"][session.user.language] }}</label>
                      <input  v-model="searchQuery" type="text" class="form-control text-dark" placeholder="Buscar dispositivo..." 
                      @input="section.deviceId = filteredDevices.length > 0 ? filteredDevices[0].id : '' "/>
          
                      <select v-if="filteredDevices.length > 0" v-model="section.deviceId" class="form-select"  @change="loadDeviceVariables(section)">
                         <option v-for="device in filteredDevices" :key="device.id" :value="device.id">
                          {{ device.label }}
                         </option>
                      </select>
                          <label v-else class="text-muted mt-2">{{ dictionary["No se encontraron dispositivos"][session.user.language] }}.</label>
                  </div>

                  <div class="col-md-6">
                    <label class="form-label">{{ dictionary["Seleccionar variables"][session.user.language] }}</label>
                    <select v-model="section.selectedVariables" multiple class="form-select"
                      @change="addVariablesToSection(section)" size="4">
                      <option v-for="variable in section.availableVariables" :key="variable.variableId"
                        :value="variable" class="text-dark">
                        {{ variable.label }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col-12">
                  <label class="form-label">{{ dictionary["Variables seleccionadas"][session.user.language] }}</label>
                  <div class="selected-variables d-flex flex-wrap gap-2">
                    <div v-for="(variable, varIndex) in section.variables" :key="varIndex"
                      class="variable-item p-2 border rounded d-flex align-items-center">
                      <span class="me-2 text-muted">{{ variable.deviceLabel }}:</span>
                      <span class="me-2 fw-bold">{{ variable.label }}</span>
                      <select v-model="variable.operation" class="form-select form-select-sm me-2">
                        <option value="historico"> {{ dictionary["Histórico"][session.user.language] }}</option>
                        <option value="sumar">{{ dictionary["Sumar"][session.user.language] }}</option>
                        <option value="restar">{{ dictionary["Restar"][session.user.language] }}</option>
                        <option value="multiplicar">{{ dictionary["Multiplicar"][session.user.language] }}</option>
                        <option value="dividir">{{ dictionary["Dividir"][session.user.language] }}</option>
                        <option value="media">{{ dictionary["Media"][session.user.language] }}</option>
                        <option value="mediana">{{ dictionary["Mediana"][session.user.language] }}</option>
                        <option value="moda">{{ dictionary["Moda"][session.user.language] }}</option>
                        <option value="desviacion">{{ dictionary["Desviación"][session.user.language] }}</option>
                        <option value="rango">{{ dictionary["Rango"][session.user.language] }}</option>
                        <option value="max">Max</option>
                        <option value="min">Min</option>
                        <option value="maxminmedia">{{ dictionary["Max-Min-Media"][session.user.language] }}</option>
                      </select>
                      <button @click="removeVariable(section, varIndex)" class="btn btn-danger btn-sm">
                        <ion-icon name="trash-outline"></ion-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-3">
            <button @click="addSection" class="btn btn-primary">
              <ion-icon name="add-circle-outline" class="me-2"></ion-icon>
              {{ dictionary["Añadir otra Sección"][session.user.language] }}
            </button>
          </div>
        </div>

        
        <!-- STEP 4: BASIC INFO -->
<div v-if="step === 4" class="step-content">
  <div class="row g-3">
    <!-- Primera columna (izquierda) -->
    <div class="col-md-6">
      <label>
        {{dictionary['Separador de comas CSV'][session.user.language]}}
      </label>
      <select v-model="newOrderData.separator" id="selectMyFieldSeparator" class="form-control" style="color:black;" disabled>
        <option value=",">
          {{dictionary['Coma'][session.user.language]}} ","
        </option>
        <option value=";">
          {{dictionary['Punto y coma'][session.user.language]}} ";"
        </option>
      </select>
    </div>

    <!-- Segunda columna (derecha) -->
    <div class="col-md-6">
      <label>
        {{dictionary['Codificación'][session.user.language]}}
      </label>
      <select v-model="newOrderData.codificacion" id="codificacion" class="form-control" style="color:black;" disabled>
        <option value="UTF-8">
          {{dictionary['UTF-8'][session.user.language]}}
        </option>
        <option value="Windows-1252">
          {{dictionary['Windows-1252'][session.user.language]}}
        </option>
      </select>
    </div>
  </div> <!-- Cierre del row -->
</div>


        <!-- STEP 3: EMAILS -->
        <div v-if="step === 3" class="step-content">
          <!-- Opción 1: Agregar email manualmente -->
          <div class="mb-3">
            <label class="form-label">  {{ dictionary["Añadir correo de notificación"][session.user.language] }}</label>
            <div class="input-group">
              <input type="email" class="form-control text-dark me-1" placeholder="Introduce un email"
                v-model="newEmail">
              <button @click="addEmail" class="btn btn-primary ms-1"> {{ dictionary["Agregar"][session.user.language] }}</button>
            </div>
          </div>

          <div v-if="newOrderData.emailList.length" class="email-list">
            <div class="list-group">
              <div v-for="(email, index) in newOrderData.emailList" :key="index"
                class="list-group-item d-flex justify-content-between align-items-center">
                {{ email }}
                <button @click="removeEmail(index)" class="btn btn-sm btn-danger">
                  <ion-icon name="trash-outline"></ion-icon>
                </button>
              </div>
            </div>
          </div>
        </div>


        <!-- STEP 5: SCHEDULE -->
        <div v-if="step === 5" class="step-content d-flex justify-content-center align-items-center gap-4">
          <div class="d-flex flex-column w-25">
            <label class="form-label mb-1 text-center">{{ dictionary["Periodicidad"][session.user.language] }}</label>
            <select v-model="newOrderData.period" class="form-select">
              <option value="mensual">{{ dictionary["Mensual"][session.user.language] }}</option>
              <option value="diario">{{ dictionary["Diario"][session.user.language] }}</option>
              <option value="inmediato">{{ dictionary["Inmediato"][session.user.language] }}</option>
            </select>
          </div>

          <div v-if="newOrderData.period === 'mensual'" class="d-flex flex-column w-25">
            <label class="form-label mb-1 text-center">{{ dictionary["Día"][session.user.language] }}</label>
            <select v-model="newOrderData.selectedDay" class="form-select">
              <option v-for="day in 31" :key="day" :value="day">{{ day }}</option>
            </select>
          </div>

          <div v-if="newOrderData.period === 'inmediato'" class="d-flex flex-column w-25">
            <label class="form-label mb-1 text-center">{{ dictionary["Fecha inicio"][session.user.language] }}</label>
            <input type="date" v-model="newOrderData.startDate" class="form-control text-dark">
          </div>

          <div v-if="newOrderData.period === 'inmediato'" class="d-flex flex-column w-25">
            <label class="form-label mb-1 text-center">{{ dictionary["Fecha fin"][session.user.language] }}</label>
            <input type="date" v-model="newOrderData.endDate" class="form-control text-dark">
          </div>
        </div>

        <!-- STEP 6: SUMMARY -->
        <div v-if="step === 6" class="step-content">
          <div class="summary-card card">
            <div class="card-body">
              <h5 class="card-title"> {{ dictionary["Resumen del informe"][session.user.language] }}</h5>
              <dl class="row">
                <dt class="col-sm-3">{{ dictionary["Nombre"][session.user.language] }}:</dt>
                <dd class="col-sm-9">{{ newOrderData.label }}</dd>

                <dt class="col-sm-3">{{ dictionary["Descripción"][session.user.language] }}:</dt>
                <dd class="col-sm-9">{{ newOrderData.description }}</dd>

                <dt class="col-sm-3">{{ dictionary["Secciones"][session.user.language] }}:</dt>
                <dd class="col-sm-9">{{ newOrderData.sections.length }}</dd>

                <dt class="col-sm-3">{{ dictionary["Notificaciones"][session.user.language] }}:</dt>
                <dd class="col-sm-9">{{ newOrderData.emailList.join(', ') || 'Ninguna' }}</dd>

                <dt class="col-sm-3">{{ dictionary["Periodicidad"][session.user.language] }}:</dt>
                <dd class="col-sm-9 text-capitalize">{{ newOrderData.period }}</dd>
              </dl>
            </div>
          </div>
        </div>

        <!-- NAVIGATION -->
        <div class="modal-footer">
          <button @click="prevStep" class="btn btn-secondary" :disabled="step === 1">
            {{ dictionary["Atrás"][session.user.language] }}
          </button>

          <button v-if="step < 5" @click="nextStep" class="btn btn-primary" :disabled="!canProceed">
            {{ dictionary["Siguiente"][session.user.language] }}
          </button>

          <button v-if="step === 5" @click="submitReport" class="btn btn-success">
             {{ dictionary["Crear informe"][session.user.language] }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/services/api';

export default {
  props: {
    session: Object,
    dictionary: Object,
    countries: Object,
    timeZones: Object,
    typesByCompany:Object,
  },
  watch: {

    step(newStep) {
      if (newStep === 2 && this.newOrderData.sections.length === 0) {
        this.addSection();
      } else if (newStep === 1 && this.newOrderData.sections.length > 0) {
        this.newOrderData.sections = [];
      }
    }
  },
  data() {
    return {
      newOrderData: {
        label: '',
        description: '',
        sections: [],
        emailList: [],
        separator: ";", 
        codificacion: "Windows-1252",
        period: 'mensual',
        endDate: new Date().toISOString().split("T")[0]
      },
      newEmail: '',
      availableDevices: [],
      searchQuery: '',
      step: 1,
      company:""
    };
  },
  computed: {
    canProceed() {
      switch (this.step) {
        case 1:
          return this.newOrderData.label && this.newOrderData.description;
        case 2:
          return (
            this.newOrderData.sections.length > 0 &&
            this.newOrderData.sections.every(
              (section) => section.label.trim() !== "" && section.variables.length > 0
            )
          );
        case 3:
        return this.newOrderData.emailList.length > 0;
        case 4:
        return this.newOrderData.codificacion.length > 0; // Validación del Step 3 
        case 5:
          if (this.newOrderData.period === "mensual") {
            return this.newOrderData.selectedDay !== undefined && this.newOrderData.selectedDay !== null;
          }
          if (this.newOrderData.period === "inmediato") {
            return this.newOrderData.startDate && this.newOrderData.endDate;
          } if (this.newOrderData.period === "diario") {
            return true
          }
          return false;

        default:
          return true;
      }
    },
    filteredDevices() {
      return this.availableDevices
        .filter(device =>
          device.label.toLowerCase().includes(this.searchQuery.toLowerCase())
        )
        .sort((a, b) => {
          let labelA = a.label.toLowerCase();
          let labelB = b.label.toLowerCase();
          if (labelA < labelB) return -1;
          if (labelA > labelB) return 1;
          return 0; 
        });
    }
  },
  methods: {
    removeSection(index) {
      this.newOrderData.sections.splice(index, 1);
    },
    async loadDeviceVariables(section) {
      try {
        const response = await api.getDeviceData(section.deviceId);
        const deviceData = api.parseDeviceFromResponse(response);

        const variables = [
          ...Object.values(deviceData.variables.analog),
          ...Object.values(deviceData.variables.digital),
          ...Object.values(deviceData.variables.dynamic)
        ];
        variables.sort((a, b) => {
          let labelA = a.label.toLowerCase();
          let labelB = b.label.toLowerCase();
          if (labelA < labelB) return -1;
          if (labelA > labelB) return 1;
          return 0; 
        });
        section.availableVariables = variables
      } catch (error) {
        console.error('Error loading device variables:', error);
      }
    },

    addVariablesToSection(section) {
      section.selectedVariables.forEach(variable => {
        section.variables.push({
          ...variable,
          deviceId: section.deviceId,
          deviceLabel: this.availableDevices.find(d => d.id === section.deviceId)?.label || 'Desconocido',
          operation: 'historico'
        });
      });
      section.selectedVariables = [];
    },

    removeVariable(section, index) {
      section.variables.splice(index, 1);
    },

    addSection() {
      const newSection = {
        label: '',
        description: '',
        deviceId: this.availableDevices[0]?.id || '',
        deviceLabel: this.availableDevices[0]?.label || '',
        availableVariables: [],
        selectedVariables: [],
        variables: []
      };

      this.newOrderData.sections.push(newSection);
      this.loadDeviceVariables(newSection);
    },

    addEmail() {
      if (this.newEmail && this.validateEmail(this.newEmail)) {
        this.newOrderData.emailList.push(this.newEmail.trim());
        this.newEmail = "";
      } else {
        alert("Ingrese un email válido.");
      }
    },

    removeEmail(index) {
      this.newOrderData.emailList.splice(index, 1);
    },

    handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        const emails = content.split(";")
          .map(email => email.trim())
          .filter(email => this.validateEmail(email));

        this.newOrderData.emailList.push(...emails);
      };

      reader.readAsText(file);
    },

    validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },

    nextStep() {
      if (this.canProceed && this.step < 6) this.step++;
    },

    prevStep() {
      if (this.step > 1) this.step--;
    },

    async submitReport() {
      try {
        // Función para formatear la fecha en formato YYYY-MM-DD en hora local
        const formatLocalDate = (date) => {
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, "0");
          const day = date.getDate().toString().padStart(2, "0");
          return `${year}-${month}-${day}`;
        };

        const calculatePlanDate = () => {
          const today = new Date();
          const todayNoTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
          const todayDateString = formatLocalDate(todayNoTime);

          if (this.newOrderData.period === "inmediato") {
            if (this.newOrderData.endDate) {
              const endDateObj = new Date(this.newOrderData.endDate);
              const endDateNoTime = new Date(
                endDateObj.getFullYear(),
                endDateObj.getMonth(),
                endDateObj.getDate()
              );
              return endDateNoTime < todayNoTime ? todayDateString : formatLocalDate(endDateNoTime);
            }
            return todayDateString;
          }

          if (this.newOrderData.period === "diario") {
            const tomorrow = new Date(todayNoTime);
            tomorrow.setDate(tomorrow.getDate() + 1);
            return formatLocalDate(tomorrow);
          }

          if (this.newOrderData.period === "mensual") {
            const selectedDay = parseInt(this.newOrderData.selectedDay, 10);
            let year = today.getFullYear();
            let month = today.getMonth() + 1; 
           
            if (today.getDate() >= selectedDay) {
              month++;
              if (month > 12) {
                month = 1;
                year++;
              }
            }
            const formattedMonth = month.toString().padStart(2, "0");
            const formattedDay = selectedDay.toString().padStart(2, "0");
            return `${year}-${formattedMonth}-${formattedDay}`;
          }

          return todayDateString;
        };

        const planDate = calculatePlanDate();       
        const cleanedSections = this.newOrderData.sections.map(section => {          
          const newSection = { ...section };
          delete newSection.availableVariables;
          delete newSection.selectedVariables;
          delete newSection.deviceId;
          delete newSection.deviceLabel;
          return newSection;
        });

      // Crear el payload con los nuevos campos
      const payload = {
        planDate,
        parametros: JSON.stringify({
          sections: cleanedSections,
          emailList: this.newOrderData.emailList,
          period: this.newOrderData.period,
          selectedDay: this.newOrderData.selectedDay || "",
          endDate: this.newOrderData.endDate || "",
          startDate: this.newOrderData.startDate || "",
          separator: this.newOrderData.separator, // Incluir el separador de campos
          codificacion: this.newOrderData.codificacion,    // Incluir la codificación
          listaDcls: [this.newOrderData.deviceId],
          company: this.company,
          language: this.session.user.language
        }),
        resumen: JSON.stringify({
          nombre: this.newOrderData.label,
          descripcion: this.newOrderData.description,
        }),
        idTipo: this.typesByCompany.deviceTypeList[0].id
      };

      // Enviar el payload al servidor
      const response = await api.postNewReport(payload);
      if (response['status-code'] === '201' || response['status-code'] === '200') {
        alert('Informe creado exitosamente!');
        this.$emit('toggleNewOrderModal');
      } else {
        alert('Error al crear el informe');
      }
    } catch (error) {
      console.error("Error submitting report:", error);
      alert("Error al enviar el formulario");
    }
  },




    async fetchDevices() {
      try {
        const response = await api.getDeviceListByCompany({
          nifCif: this.$route.params.currentCompanyNifCif,
          limit: 0,
          offset: 0,
          where: ''
        });
        this.availableDevices = api.parseDevicesFromResponse(response);
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    }
  },
  async created() {
    await this.fetchDevices();
    this.company=this.$route.params.currentCompanyNifCif   
  }
};
</script>

<style scoped>
#newOrderModal {
  position: fixed;
  top: 0;
  left: 50%; 
  transform: translateX(-50%);
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 110vw;
  height: 100vh;
}

.modal-body {
  overflow: scroll;
  overflow-x: hidden;
  max-height: 65vh
}

.steps {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  list-style: none;
}

.step-item {
  flex: 1;
  text-align: center;
  padding: 0.5rem;
  border-bottom: 2px solid #dee2e6;
  color: #6c757d;
}

.step-item.active {
  border-color: #0d6efd;
  color: #0d6efd;
}

.step-content {
  padding: 1rem;
}

.selected-variables {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.variable-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #f8f9fa;
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.email-list {
  margin-top: 1rem;
  max-height: 200px;
  overflow-y: auto;
}

.summary-card {
  margin-top: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
</style>