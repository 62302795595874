<template>
  <!-- SIDE MENU Full -->
    <div v-if="modules[currentModule] != undefined" 
    class="nav-available-menu"> 
      <span v-if="modules[currentModule].text!='Monitorización eléctrica'" class="tx-bold tx-16 aside-anchor-ok pt-2 pe-4">
        {{dictionary[modules[currentModule].text][session.user.language].toUpperCase()}}
      </span>
      <div class="w-auto"
      v-for="page, pageName in availablePages" :key="pageName">
        <a class="menu-item-text tx-10 tx-white text-nowrap m-0 h-100 me-2"
        :class="(this.$route.params.currentDisplay===page.value)?'text-menu':'text-white'"
        @click="onAvailableMenuItemClicked(page)">
          <ion-icon :name="page.icon" :alt="dictionary[page.text][session.user.language]"
          class="menu-item-icon tx-20 align-middle ps-3 h-100"></ion-icon>
          <span class="ps-1 tx-14 aside-anchor2"
          :class="(this.$route.params.currentDisplay===page.value)?'text-menu':'text-white'"
          @click="onAvailableMenuItemClicked(page)">
            {{dictionary[page.text][session.user.language]}}
          </span>
        </a>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "AvailableMenuComponent",
    props: {
      session: {
        type: Object,
        required: true,
      },
      dictionary: {
        type: Object,
        required: true,
      },
      visibility: {
        type: Object,
        required: true,
      },
      availableModules: {
        type: Object,
        required: true,
      }
    },
    emits: ["onAvailableMenuItemClicked",  "logOutRequest" ],
    data() {
      return {
        pages: {
          dashboard: {text:"Dashboard", icon: "home-outline", value: "dashboard"},
          emplacements: {text:"Emplazamientos", icon: "location-outline", value: "emplacements"},
          commandCenters: {text:"Centros de mando", icon: "git-branch-outline", value: "commandCenters"},
          machines: {text:"Máquinas", icon: "cog-outline", value: "machines"},
          lockers: {text:"Lockers", icon: "file-tray-stacked-outline", value: "lockers"},
          bundles: {text:"Conjuntos", icon: "grid-outline", value: "bundles"},
          devices: {text:"Dispositivos", icon: "hardware-chip-outline", value: "devices"},
          products: {text:"Productos", icon: "cube-outline", value: "products"},
          stocks: {text:"Stock", icon: "layers-outline", value: "stocks"},
          receptions: {text:"Recepción", icon: "arrow-undo-outline", value: "receptions"},
          expeditions: {text:"Expedición", icon: "arrow-redo-outline", value: "expeditions"},
          orders: {text:"Pedidos", icon: "basket-outline", value: "orders"},
          refunds: {text:"Devoluciones", icon: "trash-bin-outline", value: "refunds"},
          lockings: {text:"Reservas", icon: "lock-open-outline", value: "lockings"},
          charts: {text:"Gráficas", icon: "stats-chart-outline", value: "charts"},        
          companies: {text:"Empresas", icon: "briefcase-outline", value:"companies"},
          users: {text:"Usuarios", icon: "people-outline", value:"users"},
          incidences: {text:"Incidencias", icon: "alert-circle-outline", value:"incidences"},
          alarms: {text:"Alarmas", icon: "warning-outline", value:"alarms"},        
          reports: {text:"Informes", icon: "document-outline", value:"reports"},        
        },
        modules: {
          general: {text:"General", icon: "ellipsis-horizontal-outline", value: "general"},
          devices: {text:"Dispositivos", icon: "hardware-chip-outline", value: "devices"},
          fabric: {text:"Industria 4.0", icon: "business-outline", value: "fabric"},
          storage: {text:"Almacén", icon: "grid-outline", value: "storage"},
          smartLockers: {text:"Smart Lockers", icon: "lock-open-outline", value: "smartLockers"},
          electricalMonitoring: {text:"Monitorización eléctrica", icon: "pulse-outline", value: "electricalMonitoring"}, 
        },
      }
    },
    computed: {
      currentModule() {
        return (this.$route.params.currentModule != undefined) ? this.$route.params.currentModule : "general";
      },
      availablePages() {
        let availablePages = {};
        if (this.availableModules[this.currentModule] != undefined) {
          for (let page in this.pages) {
            if (this.availableModules[this.currentModule][page] != undefined && !this.availableModules[this.currentModule][page].includes(this.pages[page].value)) {
  
              availablePages[page] = this.pages[page];
            }
          }
        }
        return availablePages;
      }
    },
    methods: {
      // NAVIGATE 
      onAvailableMenuItemClicked(page) {
        this.$emit('onAvailableMenuItemClicked');
        this.$router.replace({params: {currentDisplay: page.value, targetEntity: ""} })
      },
      // LOG OUT
      logOutRequest() {
        this.$emit('logOutRequest');
      }
    },
  }
  </script>
  