<template>
  <!-- SECTION Machines-->
  <div id="machinesSection" class="row col-12 m-0 p-0" v-if="!display.configurationView">
    <div class="row col-12 p-2 m-0 section-container justify-content-center">
      <div class="col-lg-12 col-md-12 col-12 m-0 overflow-hidden justify-content-center bg-white rounded-5 p-0">
        <div class="row col-lg-12 col-md-12 col-12  m-0 p-0 pb-3">
          <div class="position-sticky col-12 m-0 p-1 ps-4 bg-head text-white text-start border-1">
            <div class="row p-0 m-0">
              <!--Primera sección-->
              <div class="row col-xl-6 col-lg-7 col-md-12 col-12 m-0 pt-0 ps-3 pe-4 text-white rounded-5 rounded-end">
                <div class="col m-0 pt-0 ps-0 text-white d-flex flex-row">
                  <h6 class="tx-left tx-14 text-white m-0 p-0 pe-4 mt-1 pt-1 d-flex flex-row">
                    <ion-icon name="location" class="tx-18 me-2 align-bottom"></ion-icon>
                    {{ dictionary["Empresas"][session.user.language] }}
                  </h6>
                  <select
                    v-if="this.$route.params.currentDisplay === 'machines' || this.$route.params.currentDisplay === 'commandCenters' || this.targetEmplacementId == ''"
                    :value="currentCompany.nifCif" class="form-control m-0 p-0 text-nowrap bg-white border me-3"
                    style="border: solid 2px white ; color: black; min-width:110px; max-width: 240px; height: 34px;"
                    @change="onCurrentCompanyChanged">
                    <option v-if="availableCompanies[0] != undefined && availableCompanies.length > 1" value="showAll">
                      {{
                        dictionary['Mostrar todo'][session.user.language] }} </option>
                    <option v-for="company in availableCompanies" :key="company.nifCif" :v-if="company.nifCif != session.user.nifCif
                      && company.relation != undefined
                      && company.relation.referenceCompany === this.session.user.nifCif" :value="company.nifCif">
                      {{ (company.nifCif === session.user.nifCif) ? "✪ " + company.name : company.name }}
                    </option>
                  </select>
                  <select v-if="this.$route.params.currentDisplay === 'commandCenters'" :value="targetEmplacementId"
                    class="form-control m-0 p-0 text-nowrap bg-white border me-3"
                    style="border: solid 2px white ; color: black; min-width:110px; max-width: 240px; height: 34px;"
                    @change="onEmplacementChanged">
                    <option v-for="emplacement in emplacementList" :key="emplacement.nifCif" :value="emplacement.id">
                      {{ emplacement.label }}
                    </option>
                  </select>
                </div>
                <div class="row col-xl-2 col-lg-3 col-md-3 col-12 justify-content-start m-0 p-0 pt-1">
                  <button @click="onChangeDisplayType('map')" :title="dictionary['Mapa'][session.user.language]"
                    :style="(!displayType.includes('map')) ? 'opacity=1;' : 'opacity: 0.7'"
                    class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow"
                    style="width: 30px; max-height: 30px;">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="map-outline"
                          :style="(!displayType.includes('map')) ? 'opacity=1;' : 'opacity: 0.5'"
                          class="pt-0 tx-18 hydrated md" style="vertical-align: middle;"></ion-icon>
                      </span>
                    </span>
                  </button>

                  <button @click="onChangeDisplayType('table')" :title="dictionary['Tabla'][session.user.language]"
                    :style="(!displayType.includes('table')) ? 'opacity=1;' : 'opacity: 0.7'"
                    class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow"
                    style="width: 30px; max-height: 30px;">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="list-outline"
                          :style="(!displayType.includes('table')) ? 'opacity=1;' : 'opacity: 0.5'" class="tx-18 pt-0"
                          style="vertical-align: middle;"></ion-icon>
                      </span>
                    </span>
                  </button>

                  <button @click="onChangeDisplayType('cards')" :title="dictionary['Tarjetas'][session.user.language]"
                    :style="(!displayType.includes('cards')) ? 'opacity=1;' : 'opacity: 0.7'"
                    class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow"
                    style="width: 30px; max-height: 30px;">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="documents-outline"
                          :style="(!displayType.includes('cards')) ? 'opacity=1;' : 'opacity: 0.5'" class="tx-18 pt-0"
                          style="vertical-align: middle;"></ion-icon>
                      </span>
                    </span>
                  </button>
                </div>
              </div>
              <!-- Fin Primera sección-->
              <!--Segunda sección-->
              <div class="row col-xl-6 col-lg-5 col-md-11 col-12 justify-content-end p-0 m-0 pe-3">
                <div class="row col-lg-8 col-md-9 col-8 justify-content-end">
                  <span class="text-end text-white mt-1 mb-2 pe-4">
                    <ion-icon :name="(this.$route.params.currentDisplay === 'machines') ? 'cog' : 'git-branch'"
                      class="tx-18 me-2 align-bottom"></ion-icon>
                    {{ dictionary[(this.$route.params.currentDisplay === 'machines') ? "Máquinas" : "Centros de mando"][session.user.language]}}
                  </span>
                </div>
                <div class="row col-xl-2 col-lg-3 col-md-2 col-4 justify-content-start p-0 m-0 pt-1">
                  <button v-if="this.session.user.rol === '255'" id="btnDisplayNewDeviceModal"
                    :title="dictionary['Añadir centro de mando'][session.user.language]"
                    style="width: 30px; max-height: 30px;"
                    class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow"
                    @click="toggleNewMachineModal">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="add-circle-outline" class="pt-0 tx-18 hydrated" data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          :title="dictionary['Añadir centro de mando'][session.user.language]"></ion-icon>
                        <!--<span class="text-dark mt-0">{{ dictionary["Añadir "+((this.$route.params.currentDisplay==="emplacements")?'emplazamiento':'centro de mando')][session.user.language]}}</span>-->
                      </span>
                    </span>
                  </button>

                  <button v-if="editingPermission" id="btnDisplayEditEraseModal"
                    :title="dictionary['Editar centro de mando'][session.user.language]"
                    style="width: 30px; max-height: 30px;"
                    class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow"
                    @click="toggleConfigurationView">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="create-outline" class="pt-0 me-0 tx-18 hydrated"
                          :title="dictionary['Editar centro de mando'][session.user.language]"></ion-icon>
                        <!--<span class="text-dark mt-0">{{ dictionary["Añadir "+((this.$route.params.currentDisplay==="emplacements")?'emplazamiento':'centro de mando')][session.user.language]}}</span>-->
                      </span>
                    </span>
                  </button>

                  <button id="btnDisplayEditEraseModal" :title="dictionary['Actualizar'][session.user.language]"
                    style="width: 30px; max-height: 30px;"
                    class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow" @click="refreshView">
                    <span>
                      <span class="button-text text-white">
                        <ion-icon name="refresh-outline" class="pt-0 me-0 tx-16 hydrated"
                          :title="dictionary['Actualizar'][session.user.language]"></ion-icon>
                        <!--<span class="text-dark mt-0">{{ dictionary["Añadir "+((this.$route.params.currentDisplay==="emplacements")?'emplazamiento':'centro de mando')][session.user.language]}}</span>-->
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>


          <!-- CONTENT Map -->
          <div v-show="!displayType.includes('map')" :class="fullSize ? 'col-lg-12' : 'col-lg-4'"
            class=" col-md-12 col-12 m-0 ps-4 p-3 pt-0 pb-0 mt-2 h-auto ">
            <div class="p-0 text-end text-dark rounded" style="z-index: 11;  position: relative;">
              <span class="me-1 bg-white border-2 border-primary p-1" @click="togglechangeDisplaySize()">
                <ion-icon v-if="!fullSize" name="expand-outline" class="tx-16 mb-1 align-bottom"></ion-icon>
                <ion-icon v-if="fullSize" name="contract-outline" class="tx-16 mb-1 align-bottom"></ion-icon>
              </span>
            </div>
            <div id="machinesMapContent" class="col-12 bg-caja rounded-5 shadow borders-etiquetas"
              :style="displayType.includes('cards') && displayType.includes('table') ? 'min-height: 80vh; ' : 'min-height: 480px;'"
              style="z-index: 0; height: auto; margin-top: -20px;">
            </div>
          </div>

          <!-- CARDS Machines -->
          <div v-show="!displayType.includes('cards') && this.machineList[0] != undefined" id="machinesCardsContent"
            :class="fullSize ? 'col-lg-12' : 'col-lg-8'" class="col-8 m-0 p-2 h-auto"
            :style="(!display.showEverything) ? 'height: 15rem' : ''">
            <div class="p-0 text-end text-dark" style="z-index: 1;  position: relative;">
              <span class="me-1 bg-white border-2 border-primary p-1 c-pointer" @click="togglechangeDisplaySize()">
                <ion-icon v-if="!fullSize" name="expand-outline" class="tx-16 mb-1 align-bottom"></ion-icon>
                <ion-icon v-if="fullSize" name="contract-outline" class="tx-16 mb-1 align-bottom"></ion-icon>
              </span>
            </div>

            <div class="row col-12 bg-caja rounded-5 shadow borders-etiquetas" :class="fullSize ? 'ms-1 me-3' : 'ms-0'"
              style="min-height: 480px; z-index: 0; height: auto; margin-top:-20px;">
              <div v-for="machine in machineCardsByPages[machineCardsPage]" :key="machine"
                class="col-xl-2 col-md-4 col-12 m-0 p-2">
                <h6
                  class="tx-left tx-clomos-white text-center text-nowrap tx-11 m-0 p-2 ps-1 pt-2 bg-lat rounded-top-3">
                  <span id="cardLabel" :data-id="machine.id" class="m-auto c-pointer btnTargetMachine">{{
                    (machine.label.length > 18) ? (machine.label.substring(0, 18) + '...') : machine.label }}</span>
                </h6>

                <div class="row col-12 border-1 rounded-bottom-3 m-0 ps-2 pe-2 pt-1 pb-1">

                  <div class="col-12 d-flex flex-column tx-left align-middle p-0" style="height: 115px;">
                    <span class="tx-14 tx-bold">{{ dictionary['Tipo'][session.user.language] + ": " }}</span>
                    <span class="tx-11 tx-start">{{ machine.type }}</span>
                    <span class="tx-14 tx-bold">{{ dictionary['Posición'][session.user.language] + ": " }}</span>
                    <span class="tx-11 tx-start">{{ dictionary['Latitud'][session.user.language] + ": "}}{{ Number(machine.geoJSON.geometry.coordinates[0]) === 0 ?
                        Number(machine.geoJSON.geometry.coordinates[0]) :
                        Number(machine.geoJSON.geometry.coordinates[0]).toFixed(2) }}</span>
                    <span class="tx-11 tx-start">{{ dictionary['Longitud'][session.user.language] + ": "}}{{ Number(machine.geoJSON.geometry.coordinates[1]) === 0 ?
                        Number(machine.geoJSON.geometry.coordinates[1]) :
                        Number(machine.geoJSON.geometry.coordinates[1]).toFixed(2) }}</span>
                    <span class="d-flex justify-content-between align-items-center pt-1 pb-1">
                      <ion-icon v-if="machine.openedIncidences != '0'" name="alert-circle-outline" :data-id="machine.id"
                        :title="dictionary['Incidencias'][session.user.language] + ': ' + machine.openedIncidences"
                        class="tx-16 align-bottom btnTargetDevice" style="color:red">
                      </ion-icon>
                      <ion-icon v-if="machine.activeAlarms != '0'" name="warning-outline" :data-id="machine.id"
                        :title="dictionary['Alarmas'][session.user.language] + ': ' + machine.activeAlarms"
                        class="tx-16 align-bottom btnTargetDevice" style="color:red">
                      </ion-icon>
                    </span>
                  </div>

                </div>
              </div>
              <div v-if="machineCardsByPages.length > 1" class="col-12 d-flex flex-row m-0 p-2">
                <div v-for="page, index in machineCardsByPages" :key="page"
                  class="border rounded c-pointer m-0 me-2 tx-14"
                  :class="(machineCardsPage == index) ? 'border-secondary  bg-menu text-white' : ''"
                  style="height:1.5rem; width:1.5rem;" @click="onSetCardPage(index)">
                  <span class="tx-bold">{{ index + 1 }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- CONTENT table -->

          <div id="machinesTableContent" v-show="!displayType.includes('table')"
            :style="fullHeight ? 'min-height: 80vh;' : 'min-height: 480px;'"
            :class="fullSize ? 'col-lg-12' : 'col-lg-12'" class="row col-md-12 col-12 ps-4 p-3 pt-0 pb-0 mt-2 h-auto">
            <div id="tableContent" class="col-12 bg-caja rounded-5 pt-2 shadow table-responsive borders-etiquetas"
              style=" z-index: 0; height: auto; margin-top:20px;">
              <table id="machinesDataTable" class="table table-striped pt-0 pb-3 bg-caja">
                <thead class="bg-lat tx-14 p-0 text-white text-center ">
                  <tr>
                    <th v-for="column in machinesColumns" :key="column.data" :width="column.width"
                      class="fw-medium p-1">
                      {{ dictionary[column.text][session.user.language] }}
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>

          <div v-show="!displayType.includes('cards') && this.machineList[0] == undefined"
            class="m-3 p-3 border rounded">
            <span class="tx-14">
              {{ dictionary['No hay máquinas disponibles'][session.user.language] }}
            </span>
          </div>

        </div>
      </div>

    </div>
  </div>
  <!-- MODAL New Machine -->
  <NewMachineModal v-if="display.newMachineModal" :session="session" :dictionary="dictionary" :countries="countries"
    :timeZones="timeZones" :currentCompany="currentCompany" v-on:toggleNewMachineModal="toggleNewMachineModal">
  </NewMachineModal>

  <!-- Configuration CommandCenters -->
  <div v-if="display.configurationView">
    <CommandCentersConfigurationComponent :session="session" :dictionary="dictionary" :countries="countries"
      :visibility="visibility" :timeZones="timeZones" :currentCompany="currentCompany"
      :availableCompanies="availableCompanies" :targetEmplacementId="targetEmplacementId"
      :dataTableOptions="dataTableOptions" v-on:toggleConfigurationView="toggleConfigurationView">
    </CommandCentersConfigurationComponent>
  </div>

</template>

<script>
import $ from 'jquery';
import Leaflet from 'leaflet';
import { MarkerClusterGroup } from 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import CommandCentersConfigurationComponent from '@/components/electricalMonitoring/CommandCentersConfigurationComponent';
import NewMachineModal from '@/components/machines/NewMachineModal';
// Clomos
import api from '@/services/api';

export default {
  name: "CommandCentersComponent",
  components: { NewMachineModal, CommandCentersConfigurationComponent },
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    visibility: {
      type: Object,
      required: true,
    },
    currentCompany: {
      type: Object,
      required: true,
    },
    availableCompanies: {
      type: Object,
      required: true,
    },
    targetEmplacementId: {
      type: String,
      required: true,
    },
    dataTableOptions: {
      type: Object,
      required: true,
    },
  },
  watch: {
    currentCompany: async function () {
      await this.getEmplacementList();
      if (this.$route.params.currentDisplay === 'machines' || this.targetEmplacementId == '') {
        await this.getMachinesDataByCompany();
        this.fullSize = false;
      }
      else {
        await this.getEmplacementData();
        await this.getMachinesDataByEmplacement();
        this.$nextTick(() => {
          this.displayMachinesMap();
        });
        this.fullSize = false;
        this.displayMachinesDataTable();

      }

      if (this.machineList[0] != undefined && this.targetMachineId === "")
        this.targetMachineId = this.machineList[0].id
    },
    targetEmplacementId: async function () {
      if (this.$route.params.currentDisplay === 'machines' || this.targetEmplacementId == '') {
        await this.getMachinesDataByCompany();
      }
      else {
        await this.getEmplacementData();
        await this.getMachinesDataByEmplacement();
        this.$nextTick(() => {
          this.displayMachinesMap();
        });
        this.fullSize = false;
        this.displayMachinesDataTable();
      }

      if (this.machineList[0] != undefined && this.targetMachineId === "")
        this.targetMachineId = this.machineList[0].id;
    },
    displayType: {
      handler: async function (newValue) {

        if (newValue.includes("cards") && newValue.includes("map") && !newValue.includes("table")) {
          this.fullHeight = true;
        } else {
          this.fullHeight = false;
        }
        if (!newValue.includes("cards") && newValue.includes("map")) this.fullSize = true;
        const currentDisplay = this.$route.params.currentDisplay;
        const module = this.$route.params.currentModule;
        let moduleData = JSON.parse(localStorage.getItem(module)) || {};
        moduleData[currentDisplay] = moduleData[currentDisplay] || {};
        moduleData[currentDisplay].displayType = newValue;
        localStorage.setItem(module, JSON.stringify(moduleData));
      },
      deep: true
    },
    fullSize: async function (newValue) {
      const currentDisplay = this.$route.params.currentDisplay;
      const module = this.$route.params.currentModule;
      let moduleData = JSON.parse(localStorage.getItem(module)) || {};
      moduleData[currentDisplay] = moduleData[currentDisplay] || {};
      moduleData[currentDisplay].fullSize = newValue;
      localStorage.setItem(module, JSON.stringify(moduleData));
    },

    fullHeight(newValue) {
      const dataTable = $("#machinesDataTable").DataTable();
      dataTable.page.len(newValue ? 20 : 10).draw();
    }
  },
  data() {
    return {
      machinesColumns: [
        {
          text: "Nombre", data: "machine", width: "15%", className: "tx-left  align-middle",
          render: (data) => {
            return "<span data-id=" + data.id + " class='text-start tx-12 tx-bold w-100  btnTargetMachine c-pointer'>" + data.label + "</span>"
          }
        },

        {
          text: "Fecha de comunicación", data: "machineCommunicationDateTime", width: "15%", className: "tx-left align-middle",
          render: (data) => {
            return (data != "")
              ? "<span style='display: none;'>" + data.getTime() + "</span> <spam>" + api.parseDateUserTimezone(data, this.session.user, this.$dayjs) + "</spam>"
              : "<span style='display: none;'></span> <spam>-</spam>";
          }
        },
        {
          text: "Emplazamiento", data: "machineParent", width: "15%", className: "tx-left  align-middle",
          render: (data) => {
            return data.parentLabel;
          }
        },
        {
          text: "Alarmas", data: "machine", width: "15%", className: "tx-left  align-middle",
          render: (data) => {
            const activeAlarms = data.activeAlarms === '0' ? '-' : data.activeAlarms
            const className = activeAlarms !== '-' ? "btnTargetMachine c-pointer" : "";
            return `
            <span data-id="${data.id}" 
                  class="text-start tx-12 tx-bold w-100 ${className}">
              ${activeAlarms}
            </span>`;
          }
        },
        {
          text: "Incidencias", data: "machine", width: "15%", className: "tx-left  align-middle",
          render: (data) => {
            let openedIncidences = data.openedIncidences === '0' ? '-' : data.openedIncidences
            const className = openedIncidences !== '-' ? "btnTargetMachine c-pointer" : "";
            return `
            <span data-id="${data.id}" 
                  class="text-start tx-12 tx-bold w-100 ${className}">
              ${openedIncidences}
            </span>`;
          }
        },
        {
          text: "Posición", data: "machine", width: "15%", className: "tx-left align-middle",
          render: (data) => {
            const lat = Number(data.geoJSON.geometry.coordinates[0]) === 0 ? Number(data.geoJSON.geometry.coordinates[0]) : Number(data.geoJSON.geometry.coordinates[0]).toFixed(2)
            const long = Number(data.geoJSON.geometry.coordinates[1]) === 0 ? Number(data.geoJSON.geometry.coordinates[1]) : Number(data.geoJSON.geometry.coordinates[1]).toFixed(2)
            const className = "btnTargetLocker c-pointer";
            return `
            <span data-id="${data.id}" 
                  class="text-start tx-12 tx-bold w-100 ${className}">
              Lat: ${lat}, Lon: ${long}
            </span>`;
          }
        },
        {
          text: "",
          data: "distributionGroup",
          visible: false,
        }
      ],
      targetEmplacementData: {},
      targetMachineId: this.$route.params.targetEntity,
      machineList: [],
      emplacementList: [],
      machinesMap: undefined,
      machineOrganigram: [],
      machineOrganigramLoading: false,
      treeConfig: { nodeWidth: 250, nodeHeight: 130, levelHeight: 130 },
      displayType: [],
      machineCardsPage: localStorage.getItem('commandCenterCardsState') ? localStorage.getItem('commandCenterCardsState') : 0,
      display: {
        showEverything: true,
        configurationView: false,
        newMachineModal: false,
      },
      linkAction: "link",
      emplacementLinkedId: '',
      fullSize: false,
      editingPermission: false,
      fullHeight: false,
      deviceTypeList: {},
      machineTypeList: {},
      isUpdatingMap: false
    }
  },
  computed: {
    machineCardsByPages() {
      localStorage.removeItem('commandCenterCardsState');
      let machineCardsByPages = []
      let i = 0;
      while (i * 12 < this.machineList.length) {
        machineCardsByPages.push(this.machineList.slice(i * 12, i * 12 + 12));
        i++;
      }
      return machineCardsByPages;
    },
  },
  emits: ["setCurrentCompany", "setEmplacement", "getAvailableCompanies", "handleHasRol"],
  methods: {
    // DISPLAY

    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({ params: { currentCompanyNifCif: value } })
      this.$emit('setCurrentCompany', value);
    },
    onEmplacementChanged(event) {
      let value = event.target.value;
      this.$emit('setEmplacement', value);
    },

    onChangeDisplayType(type) {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.machinesMap) {
            this.machinesMap.invalidateSize();
          }
        }, 300);

      });
      const index = this.displayType.indexOf(type);
      if (index !== -1) {
        this.displayType.splice(index, 1);
        if (type !== 'table') this.togglechangeDisplaySize()
        if (type === "blueprint") this.displayMachinesBlueprint();
        if (type === "map") {
          this.$nextTick(() => {
            this.displayMachinesMap()
          });
        } else if (type === "table") {
          this.$nextTick(() => {
            this.displayMachinesDataTable();
          });
        }
      } else {
        if (type !== 'table') this.togglechangeDisplaySize()
        this.displayType.push(type);
        this.displayType = [...this.displayType];
      }

      setTimeout(() => {
        this.setButtonFunctions();
      }, 100);
    },
    onSetCardPage(index) {
      this.machineCardsPage = index;
      this.setButtonFunctions();
    },
    displayMachinesBlueprint() {
      setTimeout(() => {
        $(".machineBlueprintMarker").get().forEach(marker => {
          let markerId = marker.getAttribute("data-id");
          for (let i in this.machineList) {
            if (this.machineList[i].id === markerId)
              marker.style = this.bluePrintMarkerPosition(this.machineList[i]);
          }
        })
      }, 1000);
      let src = "";
      if (this.targetEmplacementData.variables != undefined
        && this.targetEmplacementData.variables.dynamic != undefined
        && this.targetEmplacementData.variables.dynamic.urlPlano != undefined)
        src = this.targetEmplacementData.variables.dynamic.urlPlano.value;
      if (src != undefined && src != '') {
        try {
          return this.$clientConfig.api + '/ficheros/ficheros.php?fichero=' + src + '&identificadorusuario=' + this.session.user.id + '&Token=' + localStorage.getItem("ClomosAuthToken");
        }
        catch (e) {
          console.warn(e);
        }
      }
      return require('/src/assets/img/placeholder_image.jpg');
    },
    bluePrintMarkerPosition(machine) {
      let markerX = 0;
      let markerY = 0;
      let blueprintCanvas = document.getElementById('emplacementBlueprintForMachines');
      if (blueprintCanvas != null) {
        let ratioX = blueprintCanvas.naturalWidth / blueprintCanvas.offsetWidth;
        let ratioY = blueprintCanvas.naturalHeight / blueprintCanvas.offsetHeight;

        let domX = machine.geoJSON.geometry.coordinates[0] - blueprintCanvas.offsetLeft + 7;
        let domY = machine.geoJSON.geometry.coordinates[1] - blueprintCanvas.offsetTop + 7;

        markerX = Math.floor(domX / ratioX);
        markerY = Math.floor(domY / ratioY);
      }
      return "position: absolute; top: " + markerY + "px; left: " + markerX + "px;"
    },
    displayMachinesMap() {
      if (this.isUpdatingMap) return; // Si ya está en ejecución, salir
      this.isUpdatingMap = true;
      try {
        const mapContainer = document.getElementById("machinesMapContent");
        if (!mapContainer || mapContainer.offsetWidth === 0 || mapContainer.offsetHeight === 0) {
          return;
        }

        if (this.machinesMap) {
          this.machinesMap.eachLayer((layer) => this.machinesMap.removeLayer(layer));
          this.machinesMap.off();
          this.machinesMap.remove();
          this.machinesMap = undefined;
        }


        const machinesMap = Leaflet.map("machinesMapContent");
        Leaflet.tileLayer(
          `https://tile.openstreetmap.org/{z}/{x}/{y}.png?lang=${this.session.user.language}`,
          { maxZoom: 19 }
        ).addTo(machinesMap);

        const siteMarkersGroup = new MarkerClusterGroup({ maxClusterRadius: 1 });
        machinesMap.addLayer(siteMarkersGroup);

        let machinesMapListForGroup = [];
        siteMarkersGroup.addLayers(
          this.machineList.map((m) => {
            const options = { title: m.label, clickable: true, draggable: false };
            let coordinates = [0, 0];

            if (
              m.parent === "" ||
              this.$route.params.currentModule === "electricalMonitoring"
            ) {
              coordinates = [
                m.geoJSON.geometry.coordinates[0],
                m.geoJSON.geometry.coordinates[1],
              ];
            } else {
              for (let ii of this.emplacementList) {
                if (m.parent === ii.id) {
                  coordinates = [
                    ii.geoJSON.geometry.coordinates[0],
                    ii.geoJSON.geometry.coordinates[1],
                  ];
                  break;
                }
              }
            }

            function getIconType(activeAlarms, openedIncidences) {
              if (activeAlarms !== '0' && openedIncidences !== '0') return 'location';
              if (activeAlarms !== '0' && openedIncidences === '0') return 'warning';
              if (activeAlarms === '0' && openedIncidences !== '0') return 'alert-circle';
              return 'location';
            }

            const iconType = getIconType(m.activeAlarms, m.openedIncidences);
            const color =
              iconType === 'warning' || iconType === 'alert-circle'
                ? 'red'
                : iconType === 'location' && m.activeAlarms === '0' && m.openedIncidences === '0'
                  ? 'green'
                  : 'red';
            const iconHTML = `
        <ion-icon name="${iconType}" data-id="${m.id}" 
          class="btnTargetMachine border-white tx-36 c-pointer" 
          style="vertical-align: middle; color: ${color};">
        </ion-icon>`;

            const customIcon = Leaflet.divIcon({
              html: iconHTML,
              className: "",
              iconSize: [52, 52],
            });

            const marker = Leaflet.marker(coordinates, { ...options, icon: customIcon });


            marker.on("mouseover", function () {
              marker.openPopup();
            });
            marker.on("mouseout", function () {
              marker.closePopup();
            });

            marker.on("click", (e) => {
              const targetId = e.target._icon.querySelector(".btnTargetMachine").getAttribute("data-id");
              this.$router.push({ params: { currentDisplay: "commandCenter", targetEntity: targetId } });
            });


            marker.bindPopup(`
        <div>
          <strong>${m.label}</strong><br>
          Incidencias: ${m.openedIncidences}<br>
          Alarmas: ${m.activeAlarms}
        </div>
      `);

            machinesMapListForGroup.push(marker);
            return marker;
          })
        );


        siteMarkersGroup.options.iconCreateFunction = (cluster) => {
          const markers = cluster.getAllChildMarkers();
          const hasRed = markers.some((marker) =>
            marker.options.icon.options.html.includes('color: red')
          );
          const color = hasRed ? "red" : "green";
          return Leaflet.divIcon({
            html: `<div style="background-color: ${color}; width: 40px; height: 40px; border-radius: 50%; display: flex; justify-content: center; align-items: center; color: white;">${cluster.getChildCount()}</div>`,
            className: "",
          });
        };
        this.$nextTick(() => {
          machinesMap.invalidateSize();
          if (machinesMapListForGroup.length > 0) {
            const machinesMapGroup = Leaflet.featureGroup(machinesMapListForGroup);
            machinesMap.fitBounds(machinesMapGroup.getBounds());

            if (machinesMapGroup.getLayers().length < 2) {
              this.zoomLocked = true;
              this.$nextTick(() => {
                const currentZoom = machinesMap.getZoom();
                machinesMap.setZoom(currentZoom - 3);
              });
            }
          } else {
            machinesMap.setView(new Leaflet.LatLng(40.426, -3.7034), 10);
          }
        });
        this.$nextTick(() => {
          machinesMap.invalidateSize();
        });
        const attributionControl = document.querySelector(".leaflet-control-attribution");
        if (attributionControl) attributionControl.remove();
        this.machinesMap = machinesMap;
      } catch (error) {
        console.error("Error displaying the map:", error);
      } finally {
        this.isUpdatingMap = false;
      }
    },
    displayMachinesDataTable() {
      if ($.fn.DataTable.isDataTable("#machinesDataTable")) {
        $("#machinesDataTable").DataTable().clear().destroy();
      }
      const table = $("#machinesDataTable").DataTable({
        pagingType: 'full_numbers',
        pageLength: this.fullHeight ? 20 : 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.machinesColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: false,
       
      });
      for (let i in this.machineList) {
        let parentLabel = "";
        if (this.targetEmplacementId != "") {
          parentLabel = this.targetEmplacementData.label;
        }
        else {
          for (let ii in this.emplacementList) {
            if (this.emplacementList[ii].id === this.machineList[i].parent) {
              parentLabel = this.emplacementList[ii].label;
              break;
            }
          }
          if (parentLabel === "") for (let ii in this.machineList) {
            if (this.machineList[ii].id === this.machineList[i].parent) {
              parentLabel = this.machineList[ii].label;
              break;
            }
          }
        }
        table.rows.add([{
          machineId: this.machineList[i].id,
          machineEmplacement: this.machineList[i].parent,
          machine: this.machineList[i],
          machineLocation: this.machineList[i].parent,
          machineType: this.machineList[i].type,
          machineParent: { parentId: this.machineList[i].parent, parentLabel: parentLabel, machineId: this.machineList[i].id },
          machineStatus: this.machineList[i].status,
          machineCommunicationDateTime: (this.machineList[i].communicationDateTime != undefined && this.machineList[i].communicationDateTime != "") ? new Date(this.machineList[i].communicationDateTime) : "",
          machineVersion: this.machineList[i].version,
          distributionGroup: this.machineList[i].distributionGroup
        }]);
        const savedState = JSON.parse(localStorage.getItem('commandCenterDataTableState'));
        if (savedState) {
          table.page(savedState.page).draw(false);
          table.search(savedState.search).draw(false);
          table.order(savedState.order).draw(false);
          localStorage.removeItem('commandCenterDataTableState');
        }
      }
      table.draw();
      this.setButtonFunctions();
      if ($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn) => { btn.addEventListener('click', this.setButtonFunctions) });
      $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
    },
    saveTableState() {
      const table = $("#machinesDataTable").DataTable();
      const state = {
        page: table.page(),
        search: table.search(),
        order: table.order(),
      };
      localStorage.setItem('commandCenterDataTableState', JSON.stringify(state));
    },
    saveCardsState() {
      localStorage.setItem('commandCenterCardsState', this.machineCardsPage);
    },
    async toggleNewMachineModal() {
      if (this.display.newMachineModal) {
        if (this.$route.params.currentDisplay === 'machines' || this.targetEmplacementId == '') {
          await this.getMachinesDataByCompany();
          this.displayMachinesDataTable();
        }
        else {
          await this.getEmplacementData();
          await this.getMachinesDataByEmplacement();
          this.displayMachinesDataTable();
        }

      }
      this.display.newMachineModal = !this.display.newMachineModal;
    },
    togglechangeDisplaySize() {
      this.fullSize = !this.fullSize;
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.machinesMap) {
            this.machinesMap.invalidateSize();
          }
        }, 300);

      });
    },
    async toggleConfigurationView() {
      if (this.display.configurationView) {
        if (this.$route.path.includes('commandCenters')) {
          const path = this.$route.path;
          const commandCenterId = path.split('commandCenters/')[1];
          if (commandCenterId) {
            this.emplacementLinkedId = commandCenterId;


          }
        }
        await this.getEmplacementList();
        if (this.$route.params.currentDisplay === 'machines' || this.targetEmplacementId == '') {
          await this.getMachinesDataByCompany();
          this.$emit('getAvailableCompanies', (this.displayMachinesDataTable));

        }
        else if (this.targetEmplacementId != '') {
          await this.getEmplacementData();
          await this.getMachinesDataByEmplacement();
          this.$emit('getAvailableCompanies');
        }
        if (this.machineList[0] != undefined && this.targetMachineId === "")
          this.targetMachineId = this.machineList[0].id;

        this.$nextTick(() => {
          setTimeout(() => {
            if (this.machinesMap) {
              this.machinesMap.invalidateSize();
              this.displayMachinesMap();
            }
          }, 300);

          this.$nextTick(() => {

            $("#machinesDataTable").DataTable(
              {
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: 'Bfrtip',
                buttons: [
                  { "extend": 'colvis', "className": 'btn' },
                  { "extend": 'copy', "className": 'btn ms-auto' },
                  { "extend": 'csv', "className": 'btn' },
                  { "extend": 'print', "className": 'btn me-0' },
                ],
                columns: this.machinesColumns,
                select: this.dataTableOptions.select,
                language: this.dataTableOptions.language[this.session.user.language],
                stateSave: this.dataTableOptions.stateSave,
              }
            );
            $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
            this.displayMachinesDataTable();
          });
        });
      }
      this.display.configurationView = !this.display.configurationView;

    },
    async refreshView() {
      await this.getMachinesDataByCompany();
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.machinesMap) {
            this.machinesMap.invalidateSize();
            this.displayMachinesMap();
          }
        }, 300);
        this.displayMachinesDataTable();
      });
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(() => {
        $(".btnTargetMachine").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetMachine);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET 
    async getMachinesDataByCompany(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getMachineListByCompany(request);
      this.machineList = api.parseMachinesFromResponse(response);
      this.machineList.sort((a, b) => {
        const groupA = Number(a.distributionGroup);
        const groupB = Number(b.distributionGroup);
        return groupA - groupB;
      });
      if (callback && typeof callback === "function") callback(this.machineList);
    },
    async getMachinesDataByEmplacement(callback) {
      let request = {
        emplacementId: this.targetEmplacementId,
        limit: 0,
        offset: 0,
        where: "",
      };
      let response = await api.getMachineListByEmplacement(request);
      this.machineList = api.parseMachinesFromResponse(response);
      this.machineList.sort((a, b) => {
        const groupA = Number(a.distributionGroup);
        const groupB = Number(b.distributionGroup);
        return groupA - groupB;
      });
      if (callback && typeof callback === "function") callback(this.machineList);
    },
    async getEmplacementData(callback) {
      let response = await api.getEmplacementData(this.targetEmplacementId);
      this.targetEmplacementData = api.parseEmplacementFromResponse(response);
      if (callback && typeof callback === "function") callback(this.targetEmplacementData);
    },
    async getEmplacementList(callback) {
      // Prepare the request object
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,
        where: "",
      };

      let response = await api.getEmplacementList(request);
      this.emplacementList = api.parseEmplacementsFromResponse(response);
      const isLinkedIdValid = this.emplacementList.some(
        emplacement => emplacement.id === this.emplacementLinkedId
      );
      let idToEmit = null;

      if (this.emplacementList.length > 0) {
        if (isLinkedIdValid && this.emplacementLinkedId.length > 0) {
          idToEmit = this.emplacementLinkedId;
        } else {
          const leftEmplacement = this.emplacementList.find(emplacement =>
            emplacement.label.includes('zquierda')
          );
          if (leftEmplacement) {
            idToEmit = leftEmplacement.id;
          } else {
            idToEmit = this.emplacementList[0].id;
          }
        }
        this.$emit('setEmplacement', idToEmit);
      }

      // Reset the linked ID
      this.emplacementLinkedId = '';

      // Execute the callback if provided
      if (callback && typeof callback === "function") {
        callback(this.emplacementList);
      }
    },
    // TARGET 
    onTargetMachine(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetMachineId = event.target.getAttribute('data-id');
      this.saveTableState();
      this.saveCardsState();
      this.$router.push({ params: { currentDisplay: "commandCenter", targetEntity: this.targetMachineId } });
    },
    checkEditingRoleCallback(hasPermission) {
      this.editingPermission = hasPermission;
    }
  },
  async created() {
    const currentDisplay = this.$route.params.currentDisplay;
    const module = this.$route.params.currentModule;
    const moduleData = JSON.parse(localStorage.getItem(module));
    if (moduleData && moduleData[currentDisplay]) {
      const storedData = moduleData[currentDisplay];
      if (storedData.fullSize !== undefined) {
        this.fullSize = storedData.fullSize;
      }
      if (storedData.displayType !== undefined) {
        this.displayType = storedData.displayType;
        if (!this.displayType.includes('map')) {
          this.displayMachinesMap();
        }
      }
    }
    if (this.$route.path.includes('commandCenters')) {
      const path = this.$route.path;
      const commandCenterId = path.split('commandCenters/')[1];
      if (commandCenterId) {
        this.emplacementLinkedId = commandCenterId;
      }
    }
    await this.getEmplacementList();
    if (this.$route.params.currentDisplay === 'machines' || this.targetEmplacementId == '') {
      await this.getMachinesDataByCompany();
      this.$emit('getAvailableCompanies', (this.displayMachinesDataTable));

    }
    else if (this.targetEmplacementId != '') {
      await this.getEmplacementData();
      await this.getMachinesDataByEmplacement();
      this.$emit('getAvailableCompanies');
    }
    if (this.machineList[0] != undefined && this.targetMachineId === "")
      this.targetMachineId = this.machineList[0].id;
    if (!this.displayType.includes('table')) {
      this.displayMachinesDataTable();
    }
    this.displayMachinesMap();

  },
  mounted() {
    this.$emit("handleHasRol", "editor", this.checkEditingRoleCallback);

  }
}
</script>

<style>
.btnCopyId:hover,
.btnTargetMachine:hover,
.btnLinkMachine:hover,
.btnUnlinkMachine:hover,
.btnEditMachine:hover,
.btnDeleteMachine:hover {
  color: var(--main-dark) !important;
}

#cardLabel:hover {
  color: var(--main-white) !important;
}

.node {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.node-top {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 5px 5px 0 0;
}

.node-bot {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  color: black;
  border-radius: 0 0 5px 5px;
}

table.dataTable tbody th,
table.dataTable tbody td {
  padding: 1px 3px !important;
  /* e.g. change 8x to 4px here */
}
</style>