<template>
  <div id="editDeviceModal" :class="showConfiguration === 'false' ? 'modal-overlay' : 'row col-12 m-0 p-0'">
    <div class="row col-12 p-2 m-0 section-container justify-content-center modal-fullscreen">
      <div class="col-lg-12 col-md-12 col-12 m-0 overflow-hidden justify-content-center bg-white rounded-5 p-0">
        <div class="row col-lg-12 col-md-12 col-12 table-responsive m-0 p-0 pb-3" style="overflow: hidden;">
          <div class="position-sticky col-12 m-0 p-1 ps-4 bg-head text-white text-start">

            <div class="row p-0 m-0">
              <!--Primera sección-->
              <div class="row col-xl-6 col-lg-7 col-md-12 col-12 m-0 pt-0 ps-3 pe-4 text-white rounded-5 rounded-end">
                <div class="col m-0 pt-0 ps-0 text-white d-flex flex-row">
                  <h6 class="tx-left tx-white mb-2 ps-1 pt-2">
                    <ion-icon name="hardware-chip" class="tx-18 me-2 align-bottom"></ion-icon>
                    {{ (showConfiguration === 'true' ? dictionary['Editar dispositivo'][session.user.language]:dictionary['Información del dispositivo'][session.user.language]) + ": " + editDeviceData.label }}
                  </h6>

                </div>
              </div>
              <!-- Fin Primera sección-->
              <div v-if="showConfiguration === 'true'"
                class="row col-xl-6 col-lg-5 col-md-11 col-12 justify-content-end p-0 m-0 pe-3">
                <button id="btnDisplayEditEraseModal" :title="dictionary['Volver'][session.user.language]"
                  style="width: 30px; max-height: 30px;"
                  class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow" @click="this.$router.go(-1)">
                  <span>
                    <span class="button-text text-white">
                      <ion-icon name="return-down-back-outline" class="pt-0 me-0 tx-18 hydrated"
                        :title="dictionary['Volver'][session.user.language]"></ion-icon>
                    </span>
                  </span>
                </button>
              </div>
              <div v-else class="row col-xl-6 col-lg-5 col-md-11 col-12 justify-content-end p-0 m-0 pe-3">
                <button id="btnDisplayEditEraseModal" :title="dictionary['Volver'][session.user.language]"
                  style="width: 30px; max-height: 30px;"
                  class="button-ok bg-transparent border-white mt-0 me-2 rounded shadow"
                  @click="$emit('toggleViewDeviceModal')">
                  <span>
                    <span class="button-text text-white">
                      <ion-icon name="close" class="pt-0 me-0 tx-18 hydrated"
                        :title="dictionary['Volver'][session.user.language]"></ion-icon>
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </div>

          <!-- CONTENT Edit Device -->
          <div class="bg-caja m-0 p-0 w-100 rounded-bottom-5 pe-3">
            <div id="editDeviceScroll" style="overflow: scroll; overflow-x: hidden; max-height: 98vh;">
              <div class="col-12 p-2 pe-0 pb-0">

                <!-- CONTENT Edit Device -->
                <form class="p-0 overflow-hidden d-flex flex-wrap pe-2">
                  <div class="col-12 m-0 p-2">
                    <form class="col-12 m-0 p-0 overflow-hidden">
                      <div class="row col-lg-12 col-12 container-fluid p-0 pt-2 m-0">

                        <div class="col-lg-5 col-12 rounded-5 container-fluid p-0 pt-0 m-0 mb-4">

                          <div class="col-12 row p-3 pt-0">
                            <div class="border rounded-5 row bg-menu text-white p-0 m-0">
                              <div class="col-12 row mb-2">
                                <div class="col-3 d-flex flex-column">
                                  {{ dictionary['Tipo'][session.user.language] + ": " }}
                                  <span class="tx-11 tx-start">{{ editDeviceData.type ? editDeviceData.type : '-'
                                    }}</span>
                                </div>
                                <div class="col-3 d-flex flex-column">
                                  {{ dictionary['Categoría'][session.user.language] + ": " }}
                                  <span class="tx-11 tx-start">{{ editDeviceData.category ? editDeviceData.category :
                                    '-' }}</span>
                                </div>

                                <div class="col-3 d-flex flex-column">
                                  <span class="tx-13 tx-bold mt-2">{{ dictionary['Versión'][session.user.language] + ": "
                                    }}</span>
                                  <span class="tx-11 tx-start">{{ editDeviceData.version ? editDeviceData.version :
                                    '-' }}</span>
                                </div>
                                <div class="col-3 d-flex flex-column">
                                  <span class="tx-13 tx-bold mt-2"> {{ dictionary['Comunicación'][session.user.language]
                                    +
                                    ": " }} </span>
                                  <span class="tx-11 tx-start">
                                    {{ editDeviceData.communicationDateTime !== undefined &&
                                      editDeviceData.communicationDateTime !== "" ?
                                      this.$dayjs(new Date(editDeviceData.communicationDateTime))
                                        .utc("z").local().tz(this.session.user.dateTimeZone)
                                        .format(this.session.user.dateTimeFormat + ' ' + 'HH:mm:ss') : "-" }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row col-12 p-3 pt-1 justify-content-around">
                            <div class="row bg-menu text-white m-0 pb-0 rounded-top-5 justify-content-around">
                              <!-- CONTENT Blueprint -->
                              <label class="col p-0 pb-2 pt-2 ps-3 mt-1 text-white tx-bold tx-16">
                                {{ dictionary['Generales'][session.user.language] }}
                              </label>
                              <button type="button" @click="setEditDeviceData" id="btnSubmitDeviceData"
                                v-if="showConfiguration === 'true'"
                                class="col-4 button-ok gradient-tablas border-white rounded-3 m-2"
                                style="max-height: 34px;">
                                <span class="button-text text-white">
                                  <ion-icon name="create-outline"
                                    class="text-white p-0 m-auto ms-3 tx-24 hydrated"></ion-icon>
                                  <span class="text-white m-auto">
                                    {{ dictionary['Guardar cambios'][session.user.language] }}
                                  </span>
                                </span>
                              </button>
                            </div>
                            <div class="border rounded-bottom-5 row p-0 m-0 pt-2 justify-content-around">
                              <!-- NOMBRE -->
                              <div class="col-5 p-0 tx-left">
                                <label class="p-0 text-dark tx-bold tx-12">
                                  {{ dictionary['Nombre'][session.user.language] }}
                                </label>
                                <input v-model="editDeviceData.label" id="inputEditDeviceLabel" type="text"
                                  :disabled="showConfiguration === 'false'"
                                  class="form-control form-control-line text-dark">
                              </div>
                              <!-- TIPO -->
                              <div class="col-5 p-0 tx-left">
                                <label class="p-0 pt-1 text-dark tx-bold tx-12">
                                  {{ dictionary['Localización'][session.user.language] }}
                                </label>
                                <input v-model="editDeviceData.emplacement" id="inputEditDeviceEmplacement" type="text"
                                  :disabled="showConfiguration === 'false'"
                                  class="form-control form-control-line text-dark">
                              </div>
                              <div class="col-5 p-0 tx-left">
                                <label class="p-0 pt-1 text-dark tx-bold tx-12">
                                  {{ dictionary['Identificador'][session.user.language] }}
                                </label>
                                <input v-model="editDeviceData.id" disabled id="inputEditDeviceEmplacement" type="text"
                                  class="form-control form-control-line text-dark">
                              </div>
                              <div class="col-5 p-0 tx-left">
                                <label class="p-0 pt-1 text-dark tx-bold tx-12">
                                  {{ dictionary['Activo'][session.user.language] }}
                                </label>
                                <input v-model="editDeviceData.dclId" disabled id="inputEditDeviceEmplacement"
                                  type="text" class="form-control form-control-line text-dark">
                              </div>
                              <h6
                                class="col-11 bg-light border p-1 ps-3 pe-3 mt-2 rounded border border-top-1 border-end-0 border-bottom-1 border-start-0">
                                Geoposición</h6>
                              <div class="col-5 m-2 p-0 d-flex">
                                <label class="p-0 pe-2 pt-2 tx-11">
                                  {{ dictionary['Latitud'][session.user.language] }}
                                </label>
                                <input v-model="editDeviceData.geoJSON.geometry.coordinates[0]" id="inputEditDeviceLat"
                                  :disabled="showConfiguration === 'false'" type="number" step=0.00001
                                  class="form-control form-control-line text-dark" @change="onPositionChanged">
                              </div>
                              <!-- LONGITUD -->
                              <div class="col-5 m-2 p-0 d-flex">
                                <label class="p-0 pe-2 pt-2 tx-11">
                                  {{ dictionary['Longitud'][session.user.language] }}
                                </label>
                                <input v-model="editDeviceData.geoJSON.geometry.coordinates[1]" id="inputEditDeviceLng"
                                  :disabled="showConfiguration === 'false'" type="number" step=0.00001
                                  class="form-control form-control-line text-dark" @change="onPositionChanged">
                              </div>
                              <div class="col-12 m-0 p-2">
                                <!-- CONTENT Map -->
                                <div id="deviceMapContent" class="m-0 p-0" style="width: 100%; height: 450px;">
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-7 col-12 m-0 mb-4 p-2 pt-0">
                          <div class="row bg-menu text-white m-0 p-0 pb-0 rounded-top-5 justify-content-around">
                            <!-- CONTENT Blueprint -->
                            <label class="col p-0 pb-2 pt-2 ps-3 mt-1 text-white tx-bold tx-16">
                              {{ dictionary['Variables'][session.user.language] }}
                            </label>
                            <button type="button" @click="setDeviceVariables" id="btnSubmitDeviceVariables"
                              v-if="showConfiguration === 'true'"
                              class="col-3  button-ok gradient-tablas border-white rounded-3 m-2"
                              style="max-height: 34px;">
                              <span class="button-text text-white">
                                <ion-icon name="create-outline"
                                  class="text-white p-0 m-auto ms-3 tx-24 hydrated"></ion-icon>
                                <span class="text-white m-auto">
                                  {{ dictionary['Guardar variables'][session.user.language] }}
                                </span>
                              </span>
                            </button>
                          </div>
                          <div class="row col-12 p-0 m-0 pt-3 border rounded-bottom-5 text-dark">
                            <!-- ANALOGICAS-->
                            <div v-if="Object.keys(editDeviceData.variables.analog).length > 0"
                              class='row col-12 m-0 p-0 pb-4'>
                              <span v-if=!display.analog class="col-1 bg-clomos-button hide c-pointer"
                                @click="display.analog = !display.analog">+</span>
                              <span v-if=display.analog class="col-1 bg-clomos-button hide c-pointer"
                                @click="display.analog = !display.analog">-</span>
                              <div class="col-11 m-0 p-0 ps-1">
                                <h6
                                  class="question w-100 bg-light border p-1 pb-2 ps-3 pe-3 m-0 rounded border border-top-0 border-end-0 border-bottom-1 border-start-0">
                                  {{ dictionary['Tipo numéricas'][session.user.language] }}
                                </h6>
                              </div>
                              <div class="col-12 p-0 list" v-if=display.analog>
                                <div class="row p-0 m-0">
                                  <div v-for="variable in editDeviceData.variables.analog"
                                    :key="variable.analogVariableId" class="col-4">
                                    <div class="col-12 m-0 ps-2 pe-2">
                                      <label class="col-12 tx-11 p-0 pt-2">
                                        {{ variable.label }}
                                        <span class="text-nowrap ms-auto"
                                          v-if="variable.unit != undefined && variable.unit != ''"> {{ "(" +
                                            variable.unit +
                                            ")"
                                          }} </span>
                                      </label>
                                      <input v-model="editDeviceData.variables.analog[variable.label].value"
                                        :disabled="showConfiguration === 'false'"
                                        :id="(variable.isConfiguration === 'false') ? ('editDevice' + variable.label) : 'inputEditDevice' + variable.label"
                                        type="text"
                                        :data-id="editDeviceData.variables.analog[variable.label].variableId"
                                        :data-type="editDeviceData.variables.analog[variable.label].type"
                                        class="inputEditDevice form-control form-control-line m-0 p-1"
                                        style="font-size: 0.7rem !important; color:black;">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- DIGITALES-->
                            <div v-if="Object.keys(editDeviceData.variables.digital).length > 0"
                              class='row col-12 m-0 p-0 pb-4'>
                              <span v-if=!display.digital class="col-1 bg-clomos-button hide c-pointer"
                                @click="display.digital = !display.digital">+</span>
                              <span v-if=display.digital class="col-1 bg-clomos-button hide c-pointer"
                                @click="display.digital = !display.digital">-</span>
                              <div class="col-11 m-0 p-0 ps-1">
                                <h6
                                  class="question w-100 bg-light border p-1 pb-2 ps-3 pe-3 m-0 rounded border border-top-0 border-end-0 border-bottom-1 border-start-0">
                                  {{ dictionary['Tipo digitales'][session.user.language] }}
                                </h6>
                              </div>
                              <div class="col-12 p-0 list " v-if=display.digital>
                                <div class="row p-0 m-0">
                                  <div v-for="variable in editDeviceData.variables.digital"
                                    :key="variable.digitalVariableId" class="col-4 p-0">
                                    <div class="m-0 ps-2 pe-2">
                                      <label class="col-12 tx-11 p-0 pt-2">
                                        {{ variable.label }}
                                      </label>
                                      <div :class="editDeviceData.variables.digital[variable.label].value"
                                        :id="(variable.isConfiguration === 'false') ? ('editDevice' + variable.label) : 'inputEditDevice' + variable.label"
                                        type="text" :disabled="showConfiguration === 'false'"
                                        :data-id="editDeviceData.variables.digital[variable.label].variableId"
                                        :data-type="editDeviceData.variables.digital[variable.label].type"
                                        :data-value="editDeviceData.variables.digital[variable.label].value"
                                        class="inputEditDevice form-control form-control-line border-0 m-0 p-1 tx-10"
                                        @click="handleClick(variable)">
                                        {{
                                          dictionary[editDeviceData.variables.digital[variable.label].value][session.user.language]
                                        }}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- VARIABLES DE TEXTO -->
                            <div v-if="Object.keys(editDeviceData.variables.dynamic).length > 0"
                              class='row col-12 m-0 p-0 pb-4'>
                              <span v-if=!display.dynamic class="col-1 bg-clomos-button hide c-pointer"
                                @click="display.dynamic = !display.dynamic">+</span>
                              <span v-if=display.dynamic class="col-1 bg-clomos-button hide c-pointer"
                                @click="display.dynamic = !display.dynamic">-</span>
                              <div class="col-11 m-0 p-0 ps-1">
                                <h6
                                  class="question w-100 bg-light border p-1 pb-2 ps-3 pe-3 m-0 rounded border border-top-0 border-end-0 border-bottom-1 border-start-0">
                                  {{ dictionary['Tipo texto'][session.user.language] }}
                                </h6>
                              </div>
                              <div class="col-12 p-0 list" v-if=display.dynamic>
                                <div class="row p-0 m-0">
                                  <div v-for="variable in editDeviceData.variables.dynamic"
                                    :key="variable.dynamicVariableId" class="col-4 p-0">
                                    <div class="m-0 ps-2 pe-2">
                                      <label class="col-12 tx-11 p-0 pt-2">
                                        {{ variable.label }}
                                      </label>
                                      <input v-model="editDeviceData.variables.dynamic[variable.label].value"
                                        :id="(variable.isConfiguration === 'false') ? ('editDevice' + variable.label) : 'inputEditDevice' + variable.label"
                                        type="text" :disabled="showConfiguration === 'false'"
                                        :data-id="editDeviceData.variables.dynamic[variable.label].variableId"
                                        :data-type="editDeviceData.variables.dynamic[variable.label].type"
                                        class="inputEditDevice form-control form-control-line m-0 p-1"
                                        style="font-size: 0.7rem !important; color:black;">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- Fin tipo texto -->
                          </div>
                        </div>
                        <!-- FORM My Profile (End) -->
                      </div>
                    </form>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import Leaflet from 'leaflet';
// Clomos
import api from '@/services/api';

export default {
  name: "EditDeviceModal",
  props: {
    session: {
      type: Object,
      required: true,
    },
    dictionary: {
      type: Object,
      required: true,
    },
    countries: {
      type: Object,
      required: true,
    },
    timeZones: {
      type: Object,
      required: true,
    },
    typesByCompany: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      editDeviceData: {
        id: "",
        nifCif: "",
        dataConsumed: "",
        label: "",
        dclId: "",
        dclTypeId: "",
        dclClass: "",
        type: "",
        distributionGroup: "",
        parent: "",
        isBase: "",
        isPublic: 0,
        token: "",
        imgSrc: "",
        emplacement: "",
        version: "",
        geoJSON: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [0, 0]
          }
        },
        variables: {
          analog: {},
          digital: {},
          dynamic: {},
        }
      },
      deviceConfigurationForm: {
        id: "",
        variableId: "",
        value: ""
      },
      deviceMap: undefined,
      deviceMarker: undefined,
      deviceId: this.$route.params.targetEntity,
      display: {
        analog: false,
        digital: false,
        dynamic: false,
      },
      showConfiguration: "",

    }
  },
  emits: ['toggleViewDeviceModal', "handleHasRol"],
  methods: {
    handleClick(variable) {
      if (variable.isConfiguration === 'false') {
        return;
      }
      const currentValue = this.editDeviceData.variables.digital[variable.label].value;
      this.editDeviceData.variables.digital[variable.label].value = currentValue === 'true' ? 'false' : 'true';
    },
    // DISPLAY
    displayDeviceMap() {
      if (this.deviceMap != undefined) {
        this.deviceMap.invalidateSize();
        this.deviceMap.remove();
      }

      const coordinates = this.editDeviceData.geoJSON?.geometry?.coordinates;

      if (
        !coordinates ||
        coordinates.length < 2 ||
        coordinates[0] === 0 ||
        coordinates[1] === 0
      ) {
        return;
      }

      var deviceMap = Leaflet.map("deviceMapContent", {
        scrollWheelZoom: true,
        zoomControl: true
      });

      Leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png?lang=' + this.session.user.language, { maxZoom: 30 }).addTo(deviceMap);

      if (this.showConfiguration === "true") {
        deviceMap.on('click', this.onSetNewCoordinates);
      }

      this.deviceMap = deviceMap;

      let deviceMapListForGroup = [];
      if ($(".leaflet-control-attribution").get()[0] != undefined) $(".leaflet-control-attribution").get()[0].remove();
      let options = { icon: this.mapIcon() };

      // Pintar el marcador solo si las coordenadas son válidas
      var deviceMarker = Leaflet.marker([coordinates[0], coordinates[1]], options);
      deviceMarker.addTo(deviceMap);
      deviceMapListForGroup.push(deviceMarker);

      var deviceMapGroup = Leaflet.featureGroup(deviceMapListForGroup);
      this.deviceMap.fitBounds(deviceMapGroup.getBounds());

      this.$nextTick(() => {
        const currentZoom = this.deviceMap.getZoom();
        this.deviceMap.setZoom(currentZoom - 12);
      });

      this.deviceMarker = deviceMarker;
    },
    mapIcon() {
      const iconHTML = `
        <ion-icon name=location
          class="btnTargetDevice border-white tx-36 c-pointer " 
          style="vertical-align: middle; color: blue;">
        </ion-icon>`;

      const customIcon = Leaflet.divIcon({
        html:
          iconHTML,
        className: "",
        iconSize: [52, 52],
      });
      return customIcon;
    },
    /*   displayBlueprint() {
        setTimeout(() => {
          this.onPositionChanged();
        }, 300);
        let src = "";
        if (this.editDeviceData.parent != ""
          && this.editDeviceData.geoJSON != undefined
          && this.editDeviceData.geoJSON.properties != undefined
          && this.editDeviceData.geoJSON.properties.srcId != undefined)
          src = this.editDeviceData.geoJSON.properties.srcId;
        if (src != undefined && src != '') {
          try {
            return this.$clientConfig.api + '/ficheros/ficheros.php?fichero=' + src + '&identificadorusuario=' + this.session.user.id + '&Token=' + localStorage.getItem("ClomosAuthToken");
          }
          catch (e) {
            console.warn(e);
          }
        }
        return require('/src/assets/img/placeholder_image.jpg');
      }, */
    onPositionChanged() {
      this.deviceMap.removeLayer(this.deviceMarker);
      let options = { icon: this.mapIcon() };
      this.deviceMarker = Leaflet.marker([this.editDeviceData.geoJSON.geometry.coordinates[0], this.editDeviceData.geoJSON.geometry.coordinates[1]], options).addTo(this.deviceMap);
    },
    // SET
    async setEditDeviceData() {
      let response = await api.setDeviceData(this.editDeviceData);
      if (response["status-code"] === "200") {
        window.alert("El dispositivo ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el dispositivo.");
      }
      this.getEditDeviceData();
    },
    async setDeviceVariables() {
      let variablesData = {
        deviceId: this.editDeviceData.id
      };
      $(".inputEditDevice").get().forEach(input => {
        let variableLabel = input.id.replace("inputEditDevice", "");
        let ic = false;
        if (this.editDeviceData.variables.analog[variableLabel] != undefined
          && this.editDeviceData.variables.analog[variableLabel].isConfiguration === "true")
          ic = true;
        if (this.editDeviceData.variables.digital[variableLabel] != undefined
          && this.editDeviceData.variables.digital[variableLabel].isConfiguration === "true")
          ic = true;
        if (this.editDeviceData.variables.dynamic[variableLabel] != undefined
          && this.editDeviceData.variables.dynamic[variableLabel].isConfiguration === "true")
          ic = true;
        if (ic) {
          variablesData[variableLabel] = {
            value: (input.getAttribute("data-type") === "0")
              ? (input.getAttribute("data-value") === "true")
                ? 1
                : 0
              : input.value,
            id: input.getAttribute("data-id"),
            type: input.getAttribute("data-type")
          }
        }
      });
      let response = await api.setDeviceVariables(variablesData);
      if (response["status-code"] === "200") {
        window.alert("El dispositivo ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el dispositivo.");
      }
      this.getEditDeviceData();
    },
    onSetNewCoordinates(event) {
      if (this.editDeviceData.parent != "") {
        let target = event.target;
        let totalOffsetLeft = target.offsetLeft;
        let totalOffsetTop = target.offsetTop;
        let totalScrollLeft = target.scrollLeft;
        let totalScrollTop = target.scrollTop;
        while (target.offsetParent != undefined) {
          target = target.offsetParent;
          totalOffsetLeft += target.offsetLeft;
          totalOffsetTop += target.offsetTop;
          totalScrollLeft += target.scrollLeft;
          totalScrollTop += target.scrollTop;
        }
        let ratioX = event.target.naturalWidth / event.target.offsetWidth;
        let ratioY = event.target.naturalHeight / event.target.offsetHeight;

        let domX = event.x + totalScrollLeft - totalOffsetLeft;
        let domY = event.y + totalScrollTop - totalOffsetTop;

        this.editDeviceData.geoJSON.geometry.coordinates[0] = Math.floor(domX * ratioX);
        this.editDeviceData.geoJSON.geometry.coordinates[1] = Math.floor(domY * ratioY);

        this.onPositionChanged();
      }
      else {
        this.editDeviceData.geoJSON = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [Number(event.latlng.lat), Number(event.latlng.lng)]
          }
        }
        this.deviceMap.removeLayer(this.deviceMarker);
        let options = { icon: this.mapIcon() };
        this.deviceMarker = Leaflet.marker([this.editDeviceData.geoJSON.geometry.coordinates[0], this.editDeviceData.geoJSON.geometry.coordinates[1]], options).addTo(this.deviceMap);
      }
    },
    async setDeviceConfiguration() {
      let response = await api.setDeviceConfiguration(this.deviceConfigurationForm);
      if (response["status-code"] === "200") {
        window.alert("El dispositivo ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el dispositivo.");
      }
      this.getEditDeviceData();
    },
    // GET 
    async getEditDeviceData(callback) {
      let response = await api.getDeviceData(this.deviceId);
      this.editDeviceData = api.parseDeviceFromResponse(response);
      let typeCategory = this.typesByCompany?.deviceTypeList?.filter(
        type => type.id === this.editDeviceData.dclTypeId
      );
      this.editDeviceData.category = typeCategory && typeCategory.length > 0 ? typeCategory[0].category : "";
      this.deviceConfigurationForm.id = this.editDeviceData.id;

      if (this.editDeviceData.variables.dynamic['configuracion'] != undefined) {
        this.deviceConfigurationForm = {
          id: this.editDeviceData.id,
          variableId: this.editDeviceData.variables.dynamic['configuracion'].variableId,
          value: this.editDeviceData.variables.dynamic['configuracion'].value
        };
      } else {
        this.deviceConfigurationForm = {
          id: this.editDeviceData.id,
          variableId: "",
          value: ""
        };
      }

      this.getVariables();

      if (callback && typeof callback === "function") {
        callback(this.editDeviceData);
      }
    },

    getVariables() {
      const variables = {
        analog: {},
        digital: {},
        dynamic: {},
      };

      for (let key in this.editDeviceData.variables.analog) {
        const analogVariable = this.editDeviceData.variables.analog[key];
        if (analogVariable?.isConfiguration === 'true') {
          variables.analog[key] = analogVariable;
        }
      }

      for (let key in this.editDeviceData.variables.digital) {
        const digitalVariable = this.editDeviceData.variables.digital[key];
        if (digitalVariable?.isConfiguration === 'true') {
          variables.digital[key] = digitalVariable;
        }
      }

      for (let key in this.editDeviceData.variables.dynamic) {
        const dynamicVariable = this.editDeviceData.variables.dynamic[key];
        if (dynamicVariable?.isConfiguration === 'true') {
          variables.dynamic[key] = dynamicVariable;
        }
      }

      const analogCount = Object.keys(variables.analog).length;
      const digitalCount = Object.keys(variables.digital).length;
      const dynamicCount = Object.keys(variables.dynamic).length;
      const totalVariables = analogCount + digitalCount + dynamicCount;
      if (totalVariables <= 12) {
        this.display.analog = true;
        this.display.digital = true;
        this.display.dynamic = true;
      }

      this.editDeviceData.variables = { ...variables };
    },
    checkEditingRoleCallback(hasPermission) {
      if (!hasPermission) {
        window.alert("No tiene permiso para visualizar esta pagina. Contacte con el administrador del sistema");
        this.$router.go(-1)

      }
    }
  },
  async created() {
    if (this.$route.params.currentDisplay == 'deviceconfig') {
      this.showConfiguration = 'true';
      this.$emit("handleHasRol", "editor", this.checkEditingRoleCallback);

    } else {
      this.showConfiguration = 'false';
    }
    await this.getEditDeviceData();
    setTimeout(() => { this.displayDeviceMap() }, 300);
  }
}
</script>

<style>
.modal-overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100vw !important;
  height: 100vh !important;
  z-index: 99999 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputEditDevice:disabled {
  color: dimGrey;
}



.show {
  display: none;
}

.hide:target+.show {
  display: inline;
}

.hide:target {
  display: none;
}

.hide:target~.list {
  display: inline;
}

/*style the (+) and (-) */
.hide,
.show {
  width: 40px !important;
  height: 30px;
  border-radius: 7px;
  font-size: 18px;
  color: #fff;
  text-shadow: 0 1px 0 #666;
  text-align: center;
  text-decoration: none;
  box-shadow: 1px 1px 2px #000;
  background: #666;
  opacity: .95;
  margin-right: 0;
  float: left;
  margin-left: 8px;
  margin-bottom: 5px;
}

.hide:hover,
.show:hover {
  color: #eee;
  text-shadow: 0 0 1px #666;
  text-decoration: none;
  box-shadow: 0 0 4px #222 inset;
  opacity: 1;
  margin-bottom: 5px;
}

.list p {
  height: auto;
  margin: 0;
}

.question {
  float: left;
  height: auto;
  line-height: 20px;
  padding-left: 20px;
  margin-bottom: 25px;
}
</style>